import React, { Component } from 'react';
import Loader from "react-loader-spinner";

export default class IgigLoadingIcon extends Component {
    render() {
        return (
            <div className='Loader_color'>
                <Loader type="ThreeDots" height="30" width="30" style={{ textAlign: "center", margin: this.props.margin, color: this.props.color }} />
            </div>
        )
    }
}
