import axios from 'axios';
import React, { Component } from 'react';
import IgigDropdown from '../Components/IgigDropdown';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import { PDFReader } from 'react-read-pdf';
import { MobilePDFReader } from 'react-read-pdf';




class PDFOcr extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tryOutData: [],
            selectedInput: "",
            selectedOutputData: "",
            selectedInputData: "",
            loading: false,
            loadingdropdown: false,
            pdfURL: "",
            showPdf: false
        }
    }

    componentDidMount = () => {

        this.getTryoutDataBYServiceId(this.props.serviceId);

    }

    onSampleChange = (e) => {
        this.setState({ loading: true, showPdf: false });
       
        setTimeout(() => {
            this.setState({ loading: false });
            this.setState({
                selectedInput: e.value,
                selectedOutputData: e.value.outputData,
                selectedInputData: e.value.inputData,
                pdfURL: e.value.inputData,
                showPdf: true
            });

        }, 500);
    }

    // getTryoutDataBYServiceId
    getTryoutDataBYServiceId = (Id) => {

        this.setState({ loadingdropdown: true });

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/Marketplace/ServiceTryOutData?id=${Id}`,

        };

        axios(config)
            .then((response) => {
             
                this.setState({
                    tryOutData: response.data,
                    loadingdropdown: false
                });

                if (response.data == "") {
                    this.setState({ notFound: true });
                } else {
                    this.setState({ notFound: false });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
            });
    }


    //Step1 - 
    //Cinchy Query by id
    //Call that query from SPI controller - GetTryoutDataBYServiceId
    //
    onDocumentLoad({ total }) {
        this.setState({ total });
    }

    onPageLoad({ pageIndex, pageNumber }) {
        this.setState({ pageIndex, pageNumber });
    }
    render() {
       

        return (


            <div className="tab-pane fade show active" id="pop5" role="tabpanel" aria-labelledby="pop5-tab">
                <div className="pt-3" />
                <div className="">
                    <div className='card'>
                        <div className='card-body'>
                            <div className="col-md-12 text-left" >
                                <p >
                                    Turn your PDFs into useful data in seconds! Sensible allows you to upload any insurance document, including loss runs, policies, quotes, and ACORDs, and receive structured data in seconds. Sensible has pre-built support for many common documents and it takes just minutes to add support for less common or proprietary documents. Sensible seamlessly supports scans, and automatically classifies documents, so you don't need to know, e.g., which insurance carrier generated the policy you're submitting.
                                </p>

                            </div>
                        </div>
                    </div>

                    <div className="pt-4" />

                    <div className="card ">
                        <div className="card-body ">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlSelect1">Select PDF Document to OCR<span></span></label>
                                            {
                                                this.state.loadingdropdown ?
                                                    <IgigLoadingIcon></IgigLoadingIcon>
                                                    : <IgigDropdown
                                                        value={this.state.selectedInput}
                                                        options={this.state.tryOutData}
                                                        onChange={this.onSampleChange}
                                                        optionLabel="name"
                                                        placeholder="Select the PDF"
                                                    />
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-6"></div>


                                </div>
                                <div className='row'>
                                    <div className="col-md-6 card usercard demo1">
                                        <div className="text-left">


                                            <>
                                                {
                                                    this.state.loading ?
                                                        <>
                                                            <IgigLoadingIcon></IgigLoadingIcon>

                                                        </>
                                                        : null
                                                }

                                                {
                                                    this.state.showPdf ?
                                                        <div style={{ overflow: 'scroll', height: 650 }}>
                                                            <PDFReader url={this.state.pdfURL} minScale maxScale />
                                                        </div> : null
                                                }

                                            </>
                                        </div>
                                    </div>
                                    <div className="col-md-5 card usercard demo1">

                                        {
                                            this.state.loading ?
                                                <>
                                                    <IgigLoadingIcon></IgigLoadingIcon>

                                                </>
                                                : <>

                                                    {
                                                        this.state.selectedOutputData != "" ?
                                                            <>

                                                                <div className="text-left">
                                                                    <span style={{ fontSize: "20px" }}>Extracted Data</span>
                                                                    <p dangerouslySetInnerHTML={{ __html: this.state.selectedOutputData }}>
                                                                    </p>
                                                                </div>
                                                            </>
                                                            : null
                                                    }


                                                </>
                                        }

                                        <div />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        );
    }

}

export default PDFOcr;

