import { Redirect } from "react-router";


class CommonValues {
    static GetUserId = () => {
        return localStorage.getItem('userId');
    }
    static GetToken = () => {
        return localStorage.getItem('Token');
    }
    static GetUserName = () => {
        return localStorage.getItem('UserName');
    }
    static GetCompanyName = () => {
        return localStorage.getItem('OrgName');
    }
    static GetTenantId = () => {
        return localStorage.getItem('TenantId');
    }
    static GetRoleId = () => {
        return localStorage.getItem('RoleId');
    }
    static GetAvtarName = () => {
        return localStorage.getItem('AvtarName');
    }
    static GetFirstName = () => {
        return localStorage.getItem('FirstName');
    }

    static GetUserEmail = () => {
        return localStorage.getItem('userEmail');
    }
    static GetCompanyLogo = () => {
        return localStorage.getItem('IconURL');
    }

    static GetLastName = () => {
        return localStorage.getItem('LastName');
    }
    static GetHasGIGMarket = () => {
        var hasgig = localStorage.getItem('HasGIGMarket');
        if (hasgig == null)
            return "false";
        else
            return localStorage.getItem('HasGIGMarket') == 'false' && localStorage.getItem('HasGIGMarket') != '' ? "false" : "true";
    }
    static GetMode = () => {
        return localStorage.getItem('Mode');
    }

    static SetMode = (mode) => {
        localStorage.setItem('Mode', mode);
    }


    static SetValues = (token, userId, OrgName, UserName, RoleId, AvtarName, FirstName, LastName, IconURL, HasGIGMarket, slugURL, headerText, tenantId) => {
        localStorage.setItem('Token', token)
        localStorage.setItem('userId', userId)
        localStorage.setItem('OrgName', OrgName)
        localStorage.setItem('UserName', UserName)
        localStorage.setItem('RoleId', RoleId)
        localStorage.setItem('AvtarName', AvtarName)
        localStorage.setItem('FirstName', FirstName)
        localStorage.setItem('LastName', LastName)
        localStorage.setItem('IconURL', IconURL);
        localStorage.setItem('HasGIGMarket', HasGIGMarket);
        localStorage.setItem("slugURL", slugURL);
        localStorage.setItem("HeaderText", headerText);
        localStorage.setItem("TenantId", tenantId);
    }
    static ClearValues = () => {
        localStorage.removeItem("Token");
        localStorage.removeItem("userId");
        localStorage.removeItem("OrgName");
        localStorage.removeItem("UserName");
        localStorage.removeItem("RoleId");
        localStorage.removeItem("AvtarName");
        localStorage.removeItem("FirstName");
        localStorage.removeItem("SEMSEEToken");
        localStorage.removeItem("tokentalage");
        localStorage.removeItem("slugURL");
        localStorage.removeItem("LastName");
        localStorage.removeItem("IconURL");
        localStorage.removeItem("Mode");
        localStorage.removeItem("HasGIGMarket");
        localStorage.removeItem("HeaderText");
        localStorage.removeItem("TenantId");
    }

    static SetValuesForSemsee = (token) => {
        localStorage.setItem('SEMSEEToken', token);
    }

    static SetValuesForTalage = (token) => {
        localStorage.setItem('tokentalage', token);
    }

    static GetSemseeToken = () => {
        return localStorage.getItem('SEMSEEToken');
    }

    static GetTalageToken = () => {
        return localStorage.getItem('tokentalage');
    }

    static GetSlugURLForSemsee = () => {
        return localStorage.getItem('slugURL');
    }
    static GetSlugURL = () => {
        return localStorage.getItem('slugURL');
    }

    static GetHeaderText = () => {
        return localStorage.getItem('HeaderText');
    }

    static GetGIGDashboardURL = () => {
        return "/" + CommonValues.GetSlugURL() + "/myservices";
    }

    static Logout = () => {
        let Id = this.GetTenantId();
        this.ClearValues();
        if (Id == 4) {
            window.location = "/login/nowcerts";
        }
        else {
            window.location = "/login";
        }

    }

    static CheckPermissionAndCompany() {
        let token = this.GetToken();
        if (token === null || token === "" || token === "null") {
            return 401
        }
        let orgName = this.GetCompanyName();

        if (orgName === null || orgName === "" || orgName === "null") {

            return 402
        }
        return 200;
    }

}
export default CommonValues;
