import React, { Component } from 'react'
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
var axios = require('axios');

export default class GigmarketApps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingIcons: false,
            iconUrl: []
        }
    }
    componentDidMount = () => {
        this.getAboutDetails();
    }
    getAboutDetails = () => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/marketplace/about?`,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        this.setState({
            loadingIcons: true
        })
        axios(config)
            .then((response) => {
             
                this.setState({
                    loadingIcons: false,
                    iconUrl: response.data
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingIcons: false,
                });
            });
    }
    onImageClick = (id, e) => {
      window.open(`/service/${id}`, "_blank");
      e.preventDefault();
    };
    render() {
        return (
            <>
                <h2 className="font-weight-bold">
                GigMarket Apps Partners
                </h2>
                <div className="network-effect-width">
                    <div className="row gigmarket-apps">
                      
                        {this.state.loadingIcons ?
                            <IgigLoadingIcon></IgigLoadingIcon>
                            :
                            <>
                            {this.state.iconUrl.map((abouticon, index) => {
                              return (
                                <div className="col-md-1 col-2 p-2">
                                  <a
                                    href=""
                                    onClick={(e) => this.onImageClick(abouticon.apiId, e)}
                                  >
                                    <img
                                      src={abouticon.logoUrl}
                                      alt=""
                                      className="img-fluid app-icons"
                                      title={abouticon.companyName}
                                    />
                                  </a>
                                </div>
                              );
                            })}
                          </>
                        }

                    </div>
                </div>


            </>
        )
    }
}
