import React, { Component } from 'react'
import addUser from '../../src/assets/images/add-user.svg';

export default class IgigSecondaryBtn extends Component {
    render() {
        return (
            <button
                type="button"
                className="btn btn-secondary btn-c "
                style={{ width:this.props.width }}
                onClick={this.props.onClick}
            ><img src={addUser} className="mr-2 img-white" />{this.props.text}
            </button>


        )
    }
}
