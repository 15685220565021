import React, { Component } from 'react'
import ListingFooter from '../Common/ListingFooter';
import IgigInputBox from '../Components/IgigInputBox';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import IgigErrorMessage from '../Components/IgigErrorMessage';
import IgigSuccessMessage from '../Components/IgigSuccessMessage';
import MainHeader from '../OtherCommonPages/MainHeader'
import { animateScroll as scroll } from 'react-scroll'
import VertafortextLogo from '../../src/assets/images/new-images/vertafore-text-logo.png';
import VertaforLogo from '../../src/assets/images/new-images/vertafor-logo.png';
import InsLogoPurple from '../../src/assets/images/new-images/igig-logos.png';
import Logo from '../../src/assets/images/new-images/Artboard 9@4x-8.png'


export default class VertaforeContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successMessage: "",
            firstName: "",
            lastName: "",
            email: "",
            agencyName: "",
            phoneNumber: "",
            userInfoEditable: false,
            loading: false,
            successDialog: false,
            errorMessage: ""

        }

    }

    onFirstNameChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onLastNameChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onEmailChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };


    onAgencyNameChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onContactNumberChange = (e) => {

        const re = /^[+-]?\d*(?:[.,]\d*)?$/;
        if (re.test(e.target.value)) {
            if (e.target.value.length > 10) {
                return;
            }
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    };
    checkValidation = () => {
        let returnValue = true;

        this.setState({
            errorMessageFirstName: "",
            errorMessageLastName: "",
            errorMessageEmail: "",
            errorMessageAgencyName: "",
        });

        if (this.state.firstName == null || this.state.firstName == "") {
            this.setState({
                errorMessageFirstName: "Please enter first name."
            })
            scroll.scrollToTop();
            returnValue = false;

        }
        if (this.state.lastName == null || this.state.lastName == "") {
            this.setState({
                errorMessageLastName: "Please enter last name."
            })
            scroll.scrollToTop();
            returnValue = false;
        }
        if (this.state.email == null || this.state.email == "") {
            this.setState({
                errorMessageEmail: "Please enter email."
            })
            scroll.scrollToTop();
            returnValue = false;
        }
        else {
            const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
            if (regex.test(this.state.email) === false) {
                this.setState({
                    errorMessageEmail: "Please enter a valid email address"
                });
                scroll.scrollToTop();
                returnValue = false;
            }

        }
        if (this.state.agencyName == null || this.state.agencyName == "") {
            this.setState({
                errorMessageAgencyName: "Please enter agency name."
            })
            scroll.scrollToTop();
            returnValue = false;
        }

        if (this.state.phoneNumber != null && this.state.phoneNumber != "") {
            if (this.state.phoneNumber.length < 10) {
                this.setState({
                    errorMessagePhoneNumber: "Please enter valid phone number."
                })
                scroll.scrollToTop();
                returnValue = false;
            }

        }
        return returnValue;
    }
    onVertaforeSubmitClicked = () => {
        if (this.checkValidation()) {
            var axios = require('axios');
            var data = JSON.stringify({
                "FirstName": this.state.firstName,
                "LastName": this.state.lastName,
                "AgencyName": this.state.agencyName,
                "PhoneNumber": this.state.phoneNumber,
                "Email": this.state.email
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_MarketplaceService_Url}/api/marketplace/vertaforecampaign?`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            this.setState({
                loading: true
            })
            axios(config)
                .then((response) => {

                    this.setState({
                        loading: false,
                        successMessage: "Thank you for submitting!  InsuranceGIG will be in touch with you shortly about next steps.",
                        successDialog: true,

                    })
                    scroll.scrollToTop();

                })
                .catch((error) => {

                    this.setState({
                        loading: false
                    })
                    console.log(error);
                    if (error.response != null) {
                        this.setState({
                            errorMessage: "Unknown error while submitting  vertafore details!"

                        });
                    }
                });
        }
        
    }

    render() {

        return (
            <div className='vertafore-main'>
                <div className='vertafore'>

                    <MainHeader showSidebar={this.state.showSidebar} pageName="vertafore"></MainHeader>
                    <div className="row bg-community-banner mr-0 ml-0  row-position" >
                    </div>
                    <div className="mid-container vertafor-card vertafor-reg-pos">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className='col-12 vertafore-message'>
                                        <IgigErrorMessage errorMessage={this.state.errorMessage}></IgigErrorMessage>

                                    </div>
                                    <div className="col-md-7">
                                        <div className="vertafor-reg-inst">
                                            <div className="vertafore-logos">
                                                <div className="d-flex align-items-center justify-content-center vertafore-img-div">
                                                    <img
                                                        src={VertaforLogo}
                                                        alt=""
                                                        className="img-fluid vertafore-logo mr-2"
                                                    />
                                                    <img
                                                        src={VertafortextLogo}
                                                        alt=""
                                                        className="img-fluid vertafore-textlogo"
                                                    />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center mt-3 mb-3">
                                                    <img
                                                        src={InsLogoPurple}
                                                        alt=""
                                                        className="img-fluid ins-logo mr-3"
                                                    />
                                                    <img
                                                        // src={InstextLogo}
                                                        src={Logo}
                                                        alt=""
                                                        className="ins-text-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="vertafor-title">
                                                <span className="text-18 text-white">
                                                    First of its kinds app store built uniquely for agencies to buy insurance technology faster, cheaper and with no risk!
                                                </span>
                                            </div>
                                            <div className="vertafor-details mt-4">
                                                <div className="d-flex align-items-center">
                                                    <div className="vertafor-number mr-2">1</div>
                                                    <div className="text-20 text-secondary-">
                                                        Enjoy transactional pricing for each service you choose to use.
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mt-4">
                                                    <div className="vertafor-number mr-2">2</div>
                                                    <div className="text-20 text-secondary-">
                                                        There are no long term contacts for you to sign.
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mt-4">
                                                    <div className="vertafor-number mr-2">3</div>
                                                    <div className="text-20 text-secondary-">
                                                        Services are integrated directly into your AMS.
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mt-4">
                                                    <div className="vertafor-number mr-2">4</div>
                                                    <div className="text-20 text-secondary-">
                                                        Access to technology that is typically only available in larger enterprises.
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mt-4">
                                                    <div className="vertafor-number mr-2">5</div>
                                                    <div className="text-20 text-secondary-">
                                                        No coding or integration work is required to use partner services.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="vertafor-form">
                                            <div className="vertafore-form-title">
                                                <div className="ml-3">
                                                    <h2 className='vertafore-powering-title'>Powering your possible</h2>
                                                    <span className="text-16 font-weight-bold">
                                                        We believe insurance is about relationships.{" "}
                                                    </span>
                                                </div>
                                            </div>
                                            {this.state.successDialog ?
                                                <IgigSuccessMessage message={this.state.successMessage}></IgigSuccessMessage>

                                                :
                                                <>
                                                    <div className="row mt-4">

                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="exampleInputEmail1">First Name <span className='text-danger'>*</span></label>
                                                            <IgigInputBox
                                                                errorMessage={this.state.errorMessageFirstName}
                                                                type="text"
                                                                id="firstName"
                                                                name="firstName"
                                                                onChange={this.onFirstNameChange}
                                                                value={this.state.firstName}
                                                            ></IgigInputBox>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="exampleInputEmail1">Last Name <span className='text-danger'>*</span></label>
                                                            <IgigInputBox
                                                                errorMessage={this.state.errorMessageLastName}
                                                                type="text"
                                                                id="lastName"
                                                                name="lastName"
                                                                onChange={this.onLastNameChange}
                                                                value={this.state.lastName}
                                                            ></IgigInputBox>
                                                        </div>

                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="exampleInputEmail1">Email Address <span className='text-danger'>*</span></label>
                                                            <IgigInputBox
                                                                errorMessage={this.state.errorMessageEmail}
                                                                type="text"
                                                                id="email"
                                                                name="email"
                                                                onChange={this.onEmailChange}
                                                                value={this.state.email}
                                                            ></IgigInputBox>
                                                        </div>

                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="exampleInputEmail1">Agency Name <span className='text-danger'>*</span></label>
                                                            <IgigInputBox
                                                                errorMessage={this.state.errorMessageAgencyName}
                                                                type="text"
                                                                id="agencyName"
                                                                name="agencyName"
                                                                onChange={this.onAgencyNameChange}
                                                                value={this.state.agencyName}
                                                            ></IgigInputBox>
                                                        </div>

                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                            <IgigInputBox
                                                                errorMessage={this.state.errorMessagePhoneNumber}
                                                                type="text"
                                                                id="phoneNumber"
                                                                name="phoneNumber"
                                                                onChange={this.onContactNumberChange}
                                                                value={this.state.phoneNumber}

                                                            ></IgigInputBox>
                                                        </div>

                                                    </div>
                                                    <div className="text-center mt-3">
                                                        {this.state.submitLoading || this.state.loading ?
                                                            <div className="vertafore-loading">
                                                                <IgigLoadingIcon></IgigLoadingIcon>
                                                            </div>
                                                            :
                                                            <button className="btn btn-secondary vertafore-submit-btn" onClick={this.onVertaforeSubmitClicked}>Submit</button>
                                                        }

                                                    </div>
                                                </>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <ListingFooter></ListingFooter>
            </div>
        )
    }
}
