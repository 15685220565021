import React, { Component } from 'react'
import MiddleHeader from '../Common/MiddleHeader';
import ListingFooter from '../Common/ListingFooter';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import IgigPrimaryBtn from '../Components/IgigPrimaryBtn';
import IgigInputBox from '../Components/IgigInputBox';
import AgentHeader from '../OtherCommonPages/AgentHeader';
import IgigErrorMessage from '../Components/IgigErrorMessage';
import IgigSuccessMessage from '../Components/IgigSuccessMessage';
import copyToClipboard from '../assets/images/copy.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { animateScroll as scroll, scroller } from 'react-scroll'
import CommonValues from '../Common/util';
import ErrorPage from '../Common/ErrorPage';
import googleIcon from '../../src/assets/icons/g-normal.png'
import microsoftIcon from '../../src/assets/icons/microsoft-avatar.png'

export default class GigcodeRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSidebar: false,
            firstName: "",
            lastName: "",
            agencyName: "",
            emailAddress: "",
            chooseLogo: "",
            phoneNumber: "",
            uploadLogoLoading: false,
            termCondition: false,
            logoUploadedUrl: "",
            showHideFormFields: false,
            // url: "",
            termCondition: false,
            gigCode: '',
            slugUrl: "",
            wholeUrl: "",
            copied: false,
            detailsLoading: false,
            showAlredyUserError: false,
            utmSource: "",
            errorEmailAddress: "",
            agencyCode: "",
            agentEmail: "",
            authUserId: "",
            urlDetailsLoading: false,
            showRegistrationPage: true,
            checkValidation: false
        }

    }
    componentDidMount = () => {
        window.scrollTo(0, 0);
        const searchParams = new URLSearchParams(this.props.location.search);
        const code = searchParams.get('code');
        this.setState({
            gigCode: code
        });
        if (code != "" && code != null) {
            this.OnGigcodeValid(code);
        }

        const queryString = require('query-string');
        let queries = queryString.parse(this.props.location.search);

        // Social Integration
        const query = new URLSearchParams(this.props.location.hash);
        if (query != null && query != "" && query != undefined) {

            var error_msg = query.get("error_description");

            if (error_msg != "" && error_msg != null && error_msg != undefined) {
                window.location.href = "/callback#error=access_denied&error_description=" + error_msg + "&state=signup";
            }

            var access_token = query.get("#access_token");
            if (access_token != "" && access_token != null && access_token != undefined) {
                var axios = require('axios');
                var config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_Local_Url}/validateToken?`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token}`
                    }
                };
                axios(config)
                    .then((response) => {

                        if (response.data.tenantId != null && response.data.tenantId != "" && response.data.tenantId != undefined) {
                            CommonValues.SetValues(
                                response.data.token,
                                response.data.userId,
                                response.data.orgName,
                                response.data.userName,
                                response.data.roleId,
                                response.data.avatarName,
                                response.data.firstName,
                                response.data.lastName,
                                response.data.iconURL,
                                response.data.hasGIGMarket,
                                response.data.slugURL,
                                response.data.headerText,
                                response.data.tenantId
                            );

                            if (response.data.orgName === null || response.data.orgName === "") {
                                window.location.href = "/company";
                            }
                            else if ((response.data.roleId == 3 || response.data.roleId == 2) && response.data.hasGIGMarket == true) {
                                window.location.href = "/" + response.data.slugURL + "/myservices";
                            }
                            else {
                                window.location.href = "/dashboard";
                            }
                        }
                        else {
                            if (response.data.userName != null) {
                                this.setState({
                                    emailAddress: response.data.userName,
                                })
                            }
                            if (response.data.firstName != null) {
                                this.setState({
                                    firstName: response.data.firstName,
                                })
                            }
                            if (response.data.lastName != null) {
                                this.setState({
                                    lastName: response.data.lastName,
                                })
                            }
                            if (response.data.auth0UserId != null) {
                                this.setState({
                                    authUserId: response.data.auth0UserId,
                                })
                            }
                        }
                        debugger;
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            }
        }

        if (Object.keys(queries).length > 0) {
            this.setState({ utmSource: queries.utm_source });
        }

        var isPartnerWorking = localStorage.getItem("IsPartnerWorking");
        var email = localStorage.getItem("Partner_Email");
        var firName = localStorage.getItem("Partner_FirstName");
        var lstName = localStorage.getItem("Partner_LastName");
        var contctNumber = localStorage.getItem("Partner_ContactNumber");
        var agncyName = localStorage.getItem("Partner_AgencyName");
        var agency_code = localStorage.getItem("Partner_AgencyCode");
        var agent_email = localStorage.getItem("Partner_AgentEmail");

        if (isPartnerWorking) {
            if (email != null) {
                this.setState({
                    emailAddress: email,
                })
            }
            if (firName != null) {
                this.setState({
                    firstName: firName,
                })
            }
            if (lstName != null) {
                this.setState({
                    lastName: lstName,
                })
            }
            if (contctNumber != null) {
                this.setState({
                    phoneNumber: contctNumber,
                })
            }
            if (agncyName != null) {
                this.setState({
                    agencyName: agncyName,
                })
            }
            if (agency_code != null) {
                this.setState({
                    agencyCode: agency_code,
                })
            }
            if (agent_email != null) {
                this.setState({
                    agentEmail: agent_email,
                })
            }
            localStorage.removeItem("IsPartnerWorking");
            localStorage.removeItem("Partner_Email");
            localStorage.removeItem("Partner_FirstName");
            localStorage.removeItem("Partner_LastName");
            localStorage.removeItem("Partner_ContactNumber");
            localStorage.removeItem("Partner_AgencyName");
            localStorage.removeItem("Partner_AgencyCode");
            localStorage.removeItem("Partner_AgentEmail");
        }

        // if (params.index != null && params.index != '') {
        //     this.getAgencyDetails(params.index);
        // }
        // this.setState({
        //     url: `${process.env.REACT_APP_UiUrl}`

        // })
    }

    onContinueWithGoogle = () => {
        let ddd = window.location.href
        window.location.href = process.env.REACT_APP_Auth0URL + '/authorize?response_type=token&client_id=' + process.env.REACT_APP_Auth0ClientId + '&connection=google-oauth2&redirect_uri=' + ddd + '&scope=openid profile email&prompt=login&state=signup&audience=' + process.env.REACT_APP_Auth0URL + '/api/v2/'
    }

    onContinueWithMicrosoft = () => {
        let ddd = window.location.href
        window.location.href = process.env.REACT_APP_Auth0URL + '/authorize?response_type=token&client_id=' + process.env.REACT_APP_Auth0ClientId + '&connection=windowslive&redirect_uri=' + ddd + '&scope=openid profile email&prompt=login&state=signup&audience=' + process.env.REACT_APP_Auth0URL + '/api/v2/'
    }
    OnGigcodeValid = (code) => {

        if (code != null && code != "") {
            this.setState({
                urlDetailsLoading: true
            })

            var axios = require("axios");
            let data = JSON.stringify({
                "GigCode": code
              });
              
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_MarketplaceService_Url}/api/marketplace/gigcode`,
                headers: { 
                    'Content-Type': 'application/json'
                  },
                  data : data
            };
            axios(config)
                .then((response) => {
                    this.setState({
                        urlDetailsLoading: false
                    })

                    if (response.data.length > 0) {
                        this.setState({
                            showRegistrationPage: true
                        })
                    }
                    if (response.data > 0 && this.state.checkValidation) {
                        this.onClickCheckAndRegister()
                    }
                })
                .catch((error) => {
                    this.setState({
                        urlDetailsLoading: false,
                        showRegistrationPage: false
                    })
                    console.log(error);
                    let errorMessage = "";
                    if (error.response != null) {
                        if (error.response.status == 404) {
                            this.setState({
                                showRegistrationPage: false
                            })
                        } else if(error.response.status == 400) {
                            this.setState({
                                showRegistrationPage: false
                            })
                        }
                        
                        else {
                            this.setState({
                                errorMessage:"Unknown error while getting coupon details by Code"  
                            })
                        }
                    }

                });

        }
    }
    onGigcodeChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onAgencyNameChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onFirstNameChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onLastNameChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    onContactNumberChange = (e) => {
        const re = /^[+-]?\d*(?:[.,]\d*)?$/;
        if (re.test(e.target.value)) {
            if (e.target.value.length > 10) {
                return;
            }
            this.setState({
                [e.target.name]: e.target.value
            });
        }

    };
    onEmailChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    selectCheckbox = () => {
        this.setState({
            termCondition: !this.state.termCondition
        })
    }

    checkValidation = () => {

        let returnValue = true;

        this.setState({
            errorMessageFirstName: "",
            errorMessageLastName: "",
            errorMessageEmail: "",
            errorMessageAgencyName: "",
            errorMessagePhoneNumber: "",
            errorMessage: ""
        });

        if (this.state.firstName == null || this.state.firstName == "") {
            this.setState({
                errorMessageFirstName: "Please enter first name."
            })
            scroll.scrollToTop();
            returnValue = false;

        }
        if (this.state.lastName == null || this.state.lastName == "") {
            this.setState({
                errorMessageLastName: "Please enter last name."
            })
            scroll.scrollToTop();
            returnValue = false;

        }

        if (this.state.emailAddress == null || this.state.emailAddress == "") {
            this.setState({
                errorMessageEmail: "Please enter work email."
            })
            scroll.scrollToTop();
            returnValue = false;

        }
        else {
            const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
            if (regex.test(this.state.emailAddress) === false) {
                this.setState({
                    errorMessageEmail: "Please enter a valid email address"
                });
                scroll.scrollToTop();
                returnValue = false;

            }

        }
        if (this.state.agencyName == null || this.state.agencyName == "") {
            this.setState({
                errorMessageAgencyName: "Please enter business name."
            })
            scroll.scrollToTop();
            returnValue = false;

        }

        if (this.state.phoneNumber != null && this.state.phoneNumber != "") {
            if (this.state.phoneNumber.length < 10 || this.state.phoneNumber.length > 10) {
                this.setState({
                    errorMessagePhoneNumber: "Please enter valid phone number."
                })
                scroll.scrollToTop();
                returnValue = false;
            }
        }

        if (this.state.termCondition !== true) {
            this.setState({
                errorMessage: "Please accept terms and conditions",
            });
            scroll.scrollToTop();
            returnValue = false;
        }

        return returnValue;
    }
    onCopy = () => {
        this.setState({
            copied: true
        })

    }
    onLinkClick = () => {
        window.open(this.state.wholeUrl, "_blank");
    }
    onAddCromeExtensionClick = () => {
        window.open("https://chrome.google.com/webstore/detail/insurancegig/pidaodnmlepjkmkldnfommgpckflndfg", "_blank")
    }
    onRegisterClick = () => {
        this.setState({
            checkValidation: true
        })
        if (this.state.gigCode != "" && this.state.gigCode != null) {
            this.OnGigcodeValid(this.state.gigCode);
        }
        else {
            this.onClickCheckAndRegister();
        }

    }
    onClickCheckAndRegister = () => {
        if (this.checkValidation()) {
            this.OnAgentRegister('');
        }
        scroll.scrollToTop();

    }
    OnAgentRegister = (uploadedLogo) => {
        var axios = require('axios');
        var data = JSON.stringify({
            "FirstName": this.state.firstName,
            "LastName": this.state.lastName,
            "AgencyName": this.state.agencyName,
            "ContactNo": this.state.phoneNumber,
            "Email": this.state.emailAddress,
            "AgencyLogo": uploadedLogo,
            "AcceptTerms": this.state.termCondition,
            "Parent": "",
            "UtmSource": this.state.utmSource,
            "AgencyCode": this.state.agencyCode,
            "AgentEmail": this.state.agentEmail,
            "Auth0UserId": this.state.authUserId,
            "Gigcode": this.state.gigCode
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Local_Url}/api/account/agencyonboarding/insert`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        this.setState({
            loading: true
        });

        axios(config)
            .then((response) => {

                this.setState({
                    loading: false,
                    showHideFormFields: true,
                    slugUrl: response.data,
                    wholeUrl: `${process.env.REACT_APP_UiUrl}` + `/agentonboarding/${response.data}`,
                    showAlredyUserError: false,
                    errorEmailAddress: ""
                })

                this.clearFields();
                scroll.scrollToTop();
            })
            .catch((error) => {

                this.setState({
                    loading: false
                })
                if (error.response != null) {
                    if (error.response.status === 404) {
                        this.setState({
                            showAlredyUserError: true,
                            errorEmailAddress: this.state.emailAddress

                        });
                    }
                    else {
                        this.setState({
                            errorMessage: "Unknown error while submitting  details!",
                            showAlredyUserError: false,
                            errorEmailAddress: ""
                        });
                    }

                }
                scroll.scrollToTop();
            });
    }
    onLoginLinkClicked = () => {
        window.location = ("/login");
    }
    render() {
        const { location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        return (
            <>
                {this.state.urlDetailsLoading ?
                    <div className='d-flex align-items-center justify-content-center ' style={{ height: "100vh" }} >
                        <IgigLoadingIcon></IgigLoadingIcon>
                    </div>

                    :
                    <>
                        {this.state.showRegistrationPage ?
                            <div className='agency-maindiv'>
                                <AgentHeader pageName="agencyonboarding" ></AgentHeader>

                                <div className="row bg-community-banner mr-0 ml-0 row-position">
                                    <div className="leftbg app-bg1  col-lg-3 col-md-2 col-1 pl-0 pr-0">
                                    </div>
                                    <div className="midbg  col-lg-6 col-md-8 col-10 pl-0 pr-0 ">
                                        <div className="continer2 text-left pt-4">
                                            <MiddleHeader heading1="Welcome to InsuranceGIG" heading2="Connecting Buyers and Sellers through Insurance Microservices"></MiddleHeader>

                                            <div className="mid-sec float-left account-content " >
                                                <div className="mid-sec-inner  text-center errormsgforaccountpage">
                                                    <IgigErrorMessage errorMessage={this.state.errorMessage}></IgigErrorMessage>
                                                    <IgigSuccessMessage message={this.state.successMessage}></IgigSuccessMessage>
                                                    {
                                                        this.state.showAlredyUserError ?
                                                            <p style={{
                                                                background: "#ffcdd2", border: "0 solid #e60017", color: "#73000c", fontSize: "1rem", fontWeight: "400",
                                                                padding: "0.5rem", margin: "0", borderRadius: "3px"
                                                            }}>{this.state.errorEmailAddress} already registered. Please <a onClick={(e) => this.onLoginLinkClicked(e)} style={{ color: "#007bff" }}>login</a>.
                                                            </p>
                                                            : ""
                                                    }
                                                    {this.state.showHideFormFields ?

                                                        <div className="register-msg-font successmsg-a-font text-center" style={{
                                                            textAlign: "center",
                                                            borderWidth: "0px", padding: "0.5rem 0.5rem", fontSize: "20px"
                                                        }} >
                                                            <h4>Thank you for registering!</h4><br />
                                                            <p>
                                                                {this.state.authUserId == "" ?
                                                                    <>You’ve successfully registered with InsuranceGIG. Please check your email for instructions on how to set up your password and login.<br /><br /></>
                                                                    : null
                                                                }

                                                                Here is your unique {this.state.agencyName} registration

                                                                <a onClick={this.onLinkClick} style={{ cursor: "pointer", display: "inline-block" }} className="mr-1 ml-1">  link </a>
                                                                <CopyToClipboard text={this.state.wholeUrl}
                                                                    onCopy={this.onCopy} >
                                                                    <span className='copyclipboard-div' >
                                                                        <img src={copyToClipboard} onCopy={this.onCopy} style={{ cursor: "pointer" }} />
                                                                        <span className='copyclipboard'>Copy to clipboard</span>
                                                                    </span>
                                                                </CopyToClipboard>. Use it to invite other agents to register.
                                                                <br /> <br />
                                                                <p style={{ fontWeight: "bold" }}>Be sure to <a onClick={this.onAddCromeExtensionClick} style={{ cursor: "pointer", display: "inline-block" }}> download </a> our Chrome Extension for easy access to insurtech apps, including Premium Finance.</p>
                                                                Reach out to<a href="mailto: support@insurancegig.com" className="privacypolicylink text-white ml-2" style={{ display: "inline-block" }}>support@insurancegig.com</a> if you have any questions.</p>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                {this.state.showHideFormFields ?
                                                    null :
                                                    <>
                                                        <div className="mid-sec-inner pt-3">

                                                            <div className="col-md-12 float-left mid1-right text-left agency-reg-text"><b>Registration</b></div>

                                                        </div>
                                                        {this.state.detailsLoading ? (
                                                            <IgigLoadingIcon></IgigLoadingIcon>
                                                        ) :
                                                            <>

                                                                <div className="mid-sec-inner form-group ">
                                                                    <label className="form-label mt-2">Business Name <span className="text-danger">*</span></label>
                                                                    <IgigInputBox
                                                                        errorMessage={this.state.errorMessageAgencyName}
                                                                        type="text"
                                                                        id="agencyName"
                                                                        name="agencyName"
                                                                        onChange={this.onAgencyNameChange}
                                                                        value={this.state.agencyName}
                                                                        className="form-control "
                                                                        placeholder="Enter business name"
                                                                    ></IgigInputBox>

                                                                </div>
                                                                <div className="mid-sec-inner form-group">
                                                                    <label className="form-label">First Name <span className="text-danger">*</span></label>
                                                                    <IgigInputBox
                                                                        errorMessage={this.state.errorMessageFirstName}
                                                                        type="text"
                                                                        id="firstName"
                                                                        name="firstName"
                                                                        onChange={this.onFirstNameChange}
                                                                        value={this.state.firstName}
                                                                        className="form-control "
                                                                        placeholder="Enter first name"
                                                                    ></IgigInputBox>
                                                                </div>

                                                                <div className="mid-sec-inner form-group">
                                                                    <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                                                    <IgigInputBox
                                                                        errorMessage={this.state.errorMessageLastName}
                                                                        type="text"
                                                                        id="lastName"
                                                                        name="lastName"
                                                                        onChange={this.onLastNameChange}
                                                                        value={this.state.lastName}
                                                                        className="form-control "
                                                                        placeholder="Enter last name"
                                                                    ></IgigInputBox>
                                                                </div>

                                                                <div className="mid-sec-inner form-group">
                                                                    <label className="form-label">Work Email <span className="text-danger">*</span></label>
                                                                    <IgigInputBox
                                                                        errorMessage={this.state.errorMessageEmail}
                                                                        type="text"
                                                                        id="emailAddress"
                                                                        name="emailAddress"
                                                                        onChange={this.onEmailChange}
                                                                        value={this.state.emailAddress}
                                                                        className="form-control "
                                                                        placeholder="Enter work email "
                                                                    ></IgigInputBox>

                                                                </div>

                                                                <div className="mid-sec-inner form-group">
                                                                    <label className="form-label">Contact Number</label>
                                                                    <IgigInputBox
                                                                        errorMessage={this.state.errorMessagePhoneNumber}
                                                                        type="text"
                                                                        id="phoneNumber"
                                                                        name="phoneNumber"
                                                                        onChange={this.onContactNumberChange}
                                                                        value={this.state.phoneNumber}
                                                                        className="form-control "
                                                                        placeholder="Enter contact number"
                                                                    ></IgigInputBox>

                                                                </div>
                                                                {this.state.gigCode != null && this.state.gigCode != "" ?
                                                                    <div className="mid-sec-inner form-group">
                                                                        <label className="form-label">Gigcode</label>
                                                                        <IgigInputBox
                                                                            errorMessage={this.state.errorMessageGigcode}
                                                                            type="text"
                                                                            id="gigCode"
                                                                            name="gigCode"
                                                                            value={this.state.gigCode}
                                                                            className="form-control "
                                                                            disabled={true}
                                                                        ></IgigInputBox>

                                                                    </div>
                                                                    :
                                                                    null}

                                                                <div className="mid-sec-inner ml1">
                                                                    <p>
                                                                        <input type="checkbox" id="terms" name="termCondition" value={this.state.termCondition} className="termconditioninput" onClick={this.selectCheckbox} />
                                                                        Click here to indicate that you have read and agree to InsuranceGIG <a href='/terms' target="_blank">Terms and Conditions</a>, and <a href='/privacypolicy' target="_blank">Privacy Policy</a>.
                                                                        <br /> <br />
                                                                    </p>
                                                                </div>
                                                                {this.state.authUserId != "" ? <div className="mid-sec-inner ml1 text-center">
                                                                    <div style={{ "background": "rgb(255, 250, 204)" }}>
                                                                        <span>Please provide mandatory details and click on Register to continue.</span></div>
                                                                    <br></br>
                                                                    {
                                                                        this.state.authUserId.indexOf("google") > -1 ?
                                                                            <>
                                                                                <button type="button"
                                                                                    className="btn"
                                                                                    style={{ width: "250px", border: "1px solid #6f2d80" }}
                                                                                    onClick={this.onRegisterClick}
                                                                                >
                                                                                    <img src={googleIcon} style={{ width: "30px" }} ></img>&nbsp;&nbsp;&nbsp;Register with Google</button>
                                                                                <br></br>
                                                                                <br></br>
                                                                            </>
                                                                            :
                                                                            <button type="button"
                                                                                className="btn"
                                                                                style={{ width: "250px", border: "1px solid #6f2d80" }}
                                                                                onClick={this.onRegisterClick}
                                                                            >
                                                                                <img src={microsoftIcon} style={{ width: "30px" }} ></img>&nbsp;&nbsp;&nbsp;Register with Microsoft</button>
                                                                    }
                                                                </div> :
                                                                    <div className="mid-sec-inner ml1 text-center">
                                                                        {this.state.loading ? (
                                                                            <IgigLoadingIcon></IgigLoadingIcon>
                                                                        ) :
                                                                            <IgigPrimaryBtn text="Register" onClick={this.onRegisterClick}></IgigPrimaryBtn>

                                                                        }
                                                                        <br></br>
                                                                        <br></br>
                                                                        <button type="button"
                                                                            className="btn"
                                                                            style={{ width: "280px", border: "1px solid #6f2d80" }}
                                                                            onClick={this.onContinueWithGoogle}
                                                                        >
                                                                            <img src={googleIcon} style={{ width: "30px" }} ></img>&nbsp;&nbsp;&nbsp;Register with Google</button>
                                                                        <br></br>
                                                                        <br></br>
                                                                        <button type="button"
                                                                            className="btn"
                                                                            style={{ width: "280px", border: "1px solid #6f2d80" }}
                                                                            onClick={this.onContinueWithMicrosoft}
                                                                        >
                                                                            <img src={microsoftIcon} style={{ width: "30px" }} ></img>&nbsp;&nbsp;&nbsp;Register with Microsoft</button>


                                                                    </div>
                                                                }
                                                                {/* <div className="mid-sec-inner ml1 text-center">
                                                                    {this.state.loading ? (
                                                                        <IgigLoadingIcon></IgigLoadingIcon>
                                                                    ) :
                                                                        <IgigPrimaryBtn text="Register" onClick={this.onRegisterClick}></IgigPrimaryBtn>
                                                                    }
                                                                </div> */}

                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rightbg app-bg2 col-md-2 col-lg-3 col-1 pl-0 pr-0">
                                    </div>
                                </div>
                                <div className="container-fluid mid2 mt-2">
                                    <div className="row pb-2 pt-2">
                                        <div className="col-md-12 col-12 text-left">

                                        </div>
                                    </div>
                                </div>
                                <ListingFooter></ListingFooter>

                            </div>
                            :
                            <ErrorPage />
                        }
                    </>}
            </>
        )
    }
}