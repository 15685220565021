import React, { Component } from 'react';
import axios from 'axios';

import bugLogo from '../assets/images/bug.svg';
import IgigDropdown from '../Components/IgigDropdown';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';

class Relativity6NAICS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tryOutData: [],
            selectedInput: "",
            selectedOutputData: {},
            selectedInputData: {},
            loading: false,
            loadingdropdown: false,
        }
    }
    componentDidMount = () => {

        this.getTryoutDataBYServiceId(this.props.serviceId);

    }

    onSampleChange = (e) => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.setState({
                selectedInput: e.value,
                selectedOutputData: JSON.parse(e.value.outputData).classificationData,
                selectedInputData: JSON.parse(e.value.inputData),
            });

        }, 500);
    }

    getTryoutDataBYServiceId = (Id) => {

        this.setState({ loadingdropdown: true });

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/Marketplace/ServiceTryOutData?id=${Id}`,

        };

        axios(config)
            .then((response) => {

                this.setState({
                    tryOutData: response.data,
                    loadingdropdown: false
                });

                if (response.data == "") {
                    this.setState({ notFound: true });
                } else {
                    this.setState({ notFound: false });
                }



            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
            });
    }

    render() {

        return (

            <div className='card usercard '>
            <div className="tab-pane fade show active" id="pop5" role="tabpanel" aria-labelledby="pop5-tab">
                <div className="pt-3" />
                <div className="try-it">
                    <div className="col-md-12 text-left" >
                        <p >
                            Relativity6 is the go-to API for real-time NAICS industry classifications, risk flags, existence checks, digital footprint links.  Utilizing web search, Relativity6 will search and classify any business with a digital footprint. In production with some of the biggest commercial insurance carriers, brokers, agencies and MGAs across North America and Europe.  Handle more new and renewal submissions, price policies more accurately and avoid surprise claims with Relativity6’s API.
                        </p>
                    </div>
                    <div className="pt-4" />
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="text-left">
                                    <div className="">
                                        <label htmlFor="exampleFormControlSelect1">Business Name<span>*</span></label>
                                        {
                                            this.state.loadingdropdown ?
                                                <IgigLoadingIcon></IgigLoadingIcon>
                                                : <IgigDropdown
                                                    value={this.state.selectedInput}
                                                    options={this.state.tryOutData}
                                                    onChange={this.onSampleChange}
                                                    optionLabel="name"
                                                    placeholder="Select Business Name"
                                                />
                                        }

                                    </div>

                                    {
                                        this.state.selectedInputData != null && this.state.selectedInputData != undefined ?

                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlInput1">Address</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder value={this.state.selectedInputData.address} readOnly />
                                            </div>
                                            : null
                                    }

                                    {
                                        this.state.selectedInputData != null && this.state.selectedInputData != undefined ?

                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlInput1">City</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder value={this.state.selectedInputData.city} readOnly />
                                            </div>
                                            : null
                                    }
                                    {
                                        this.state.selectedInputData != null && this.state.selectedInputData != undefined ?

                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlInput1">State</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder value={this.state.selectedInputData.state} readOnly />
                                            </div>
                                            : null
                                    }
                                    {
                                        this.state.selectedInputData != null && this.state.selectedInputData != undefined ?

                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlInput1">Zip</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder value={this.state.selectedInputData.zip} readOnly />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-8">

                                {
                                    this.state.loading ?
                                        <>
                                            <IgigLoadingIcon></IgigLoadingIcon>

                                        </>
                                        : null
                                }

                                {

                                    this.state.selectedOutputData.naicsPredictionA != null && this.state.selectedOutputData.naicsPredictionA != undefined && this.state.loading == false ?
                                        <div className="card usercard ">
                                        {/* <div className="card usercard "> */}
                                            <div className="card-body ">
                                                {
                                                    this.state.selectedInputData != null && this.state.selectedInputData != undefined ?
                                                        <h5 className="top-head"><span>Business Name:</span>{this.state.selectedInputData.companyname}</h5>
                                                        : null
                                                }
                                                {
                                                    this.state.selectedInputData != null && this.state.selectedInputData != undefined ?
                                                        <h5 className="top-head"><span>Address:</span>{this.state.selectedInputData.address}</h5>
                                                        : null
                                                }
                                                <div />
                                                <div className="mb-4  mt-4">
                                                    <h2 className="boxheading text-left">NAICS Codes <img src={bugLogo} /></h2>
                                                </div>
                                                {/* For A classification */}
                                                <div className="card-box-view text-left">
                                                    {
                                                        this.state.selectedOutputData != null && this.state.selectedOutputData.naicsPredictionA != undefined ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-6 col-md-4">
                                                                        <span className="key">NAICS Codes</span>
                                                                    </div>
                                                                    <div className="col-6 col-md-8">
                                                                        <span className="val">{this.state.selectedOutputData.naicsPredictionA.naicsCode}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6 col-md-4">
                                                                        <span className="key">Description</span>
                                                                    </div>
                                                                    <div className="col-6 col-md-8">
                                                                        <span className="val">{this.state.selectedOutputData.naicsPredictionA.naicStitle}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6 col-md-4">
                                                                        <span className="key">Confidence Score</span>
                                                                    </div>
                                                                    <div className="col-6 col-md-8">
                                                                        <span className="val">{this.state.selectedOutputData.naicsPredictionA.confidenceScore}</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null

                                                        //
                                                    }

                                                </div>
                                                {/* For B classification */}
                                                <div className="card-box-view text-left">
                                                    {
                                                        this.state.selectedOutputData != null && this.state.selectedOutputData.naicsPredictionB != undefined ?
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-6 col-md-4">
                                                                        <span className="key">NAICS Codes</span>
                                                                    </div>
                                                                    <div className="col-6 col-md-8">
                                                                        <span className="val">{this.state.selectedOutputData.naicsPredictionB.naicsCode}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6 col-md-4">
                                                                        <span className="key">Description</span>
                                                                    </div>
                                                                    <div className="col-6 col-md-8">
                                                                        <span className="val">{this.state.selectedOutputData.naicsPredictionB.naicStitle}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6 col-md-4">
                                                                        <span className="key">Confidence Score</span>
                                                                    </div>
                                                                    <div className="col-6 col-md-8">
                                                                        <span className="val">{this.state.selectedOutputData.naicsPredictionB.confidenceScore}</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : null

                                                        //
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                        : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>

        );
    }

}


export default Relativity6NAICS;
