import React, { Component } from 'react'

export default class IgigInputBox extends Component {
    render() {
        return (
            <div>
                <input
                    className={this.props.errorMessage != null && this.props.errorMessage.length > 0 ? "inputValidation form-control mb-2" : "form-control mb-2"}
                    type={this.props.type}
                    id={this.props.id}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    onKeyDown={this.props.onKeyDown}
                    autoComplete={this.props.autoComplete}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                    onkeypress={this.props.onkeypress}
                />
                <div className="validation-error-msg">
                    {this.props.errorMessage != null &&  this.props.errorMessage.length > 0 ? (
                        <span>{this.props.errorMessage}</span>
                    ) : null}
                </div>
            </div>
        )
    }
}
