import React, { Component } from 'react'
import { Message } from 'primereact/message';

export default class IgigErrorMessage extends Component {
    render() {
        return (
            <>
                {
                    this.props.errorMessage != null && this.props.errorMessage.length > 0 ?
                        <div className="p-col-12 message-padding">
                            <Message severity="error" text={this.props.errorMessage} />
                        </div>
                        : null
                }
            </>

        )
    }
}
