import React, { Component } from 'react'

export default class IgigTextarea extends Component {
    render() {
        return (
            <div>
                <textarea 
                   className={this.props.errorMessage != null && this.props.errorMessage.length > 0 ? "inputValidation form-control " : "form-control "}
                    type={this.props.type}
                    id={this.props.id}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                   rows={this.props.rows}
                 
                />
                <div className="validation-error-msg">
                    {this.props.errorMessage != null && this.props.errorMessage.length > 0 ? (
                        <span>{this.props.errorMessage}</span>
                    ) : null}
                </div>
            </div>
        )
    }
}
