import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import ReactGA from 'react-ga';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';

export default class ServiceDocumentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentDetailsList: [],
            searchText: "",
            detailsLoading: false
        }
    }
    componentDidMount = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Service details page',
            action: 'Service documentation clicked for service id: '+ this.props.serviceId,
        });
        this.getServiceDocumentByIdAndType();
    }
    apiDocBodyTemplate = (rowData) => {

        return (

            <React.Fragment>
                <a href={rowData.documentURL} style={{ color: "blue" }}>Open doc</a>
            </React.Fragment>
        );
    }

    getServiceDocumentByIdAndType = () => {
        var axios = require('axios');
     
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/marketplace/servicedocuments?id=${this.props.serviceId}&name=${this.state.searchText}`,
        
        };
        this.setState({
            detailsLoading: true
        })
        axios(config)
            .then((response) => {
               
                this.setState({
                    documentDetailsList: response.data,
                    detailsLoading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    detailsLoading: false
                })
            });

    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    searchClicked = () => {
        this.getServiceDocumentByIdAndType()
    }
    onKeyClick = (e) => {
        if (e.keyCode === 13) {
            this.getServiceDocumentByIdAndType();
        }
    }
    render() {
        return (
            <div className="tab-pane fade show active" id="pop3" role="tabpanel" aria-labelledby="pop3-tab">

                <div className="pt-3" />
                <div className="col-md-12 text-left">
                    <h3 style={{ color: '#062350', fontSize: '16px' }}><b>Check out additional documentation about this service</b></h3>
                </div>
                <div className='row'>
                    <div className="col-md-8 text-left float-left">&nbsp;</div>
                    <div className="col-md-4 " style={{ float: "right" }}>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control border-right-0"
                                placeholder="Document Search"
                                name="searchText"
                                value={this.state.searchText}
                                onChange={this.onChange}
                                onKeyDown={this.onKeyClick}
                            />
                            <span className="input-group-append bg-white">
                                <button className="btn border border-left-0" type="button" onClick={this.searchClicked}><i className="fa fa-search" style={{ color: 'gray' }} /></button>
                            </span>

                        </div>
                    </div>
                </div>
                <div className="col-md-12 text-left " style={{ paddingTop: '50px' }}>

                    {this.state.detailsLoading ?
                        <IgigLoadingIcon></IgigLoadingIcon> :
                        <>
                            <div className='displayfordesktop'>
                                <DataTable value={this.state.documentDetailsList}
                                    responsiveLayout="scroll">
                                    <Column field="documentType" header="Type" style={{ width: '30%' }}></Column>
                                    <Column field="name" header="Name" style={{ width: '30%' }} ></Column>
                                    <Column body={this.apiDocBodyTemplate} header="Link" style={{ width: '40%' }} ></Column>
                                </DataTable>
                            </div>
                            <div className='displayformobile'>
                                <DataTable value={this.state.documentDetailsList}
                                    responsiveLayout="scroll">
                                    <Column field="name" header="Name" style={{ width: '30%' }} ></Column>
                                    <Column body={this.apiDocBodyTemplate} header="Link" style={{ width: '40%' }} ></Column>
                                </DataTable>
                            </div>

                        </>
                    }
                </div>
            </div>

        )
    }
}

