import React, { Component } from 'react';
import CommonValues from '../Common/util';
import { withRouter } from 'react-router-dom';
import ListingFooter from '../Common/ListingFooter';
import IgigPrimaryBtn from '../Components/IgigPrimaryBtn';
import MiddleHeader from '../Common/MiddleHeader';
import IgigInputBox from '../Components/IgigInputBox';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import IgigTextarea from '../Components/IgigTextarea';
import IgigErrorMessage from '../Components/IgigErrorMessage';
import IgigSuccessMessage from '../Components/IgigSuccessMessage';
import MainHeader from '../OtherCommonPages/MainHeader'
import Sidebar from '../OtherCommonPages/Sidebar'
import ReactGA from 'react-ga';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';
import { animateScroll as scroll } from 'react-scroll'

var axios = require('axios');

class StartWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceId: "",
            flowName: "",
            flowDescription: "I would like to start using this workflow because...",
            loading: false,
            submitLoading: false,
            userInfoEditable: false,
            firstName: "",
            lastName: "",
            workEmail: "",
            phoneNumber: "",

            errormessageFirstName: "",
            errormessageLastName: "",
            errormessageWorkEmail: "",
            errormessageDescription: "",
            errormessageSelectService: "",
            errorMessagePhoneNumber: "",

            service: "",
            termCondition: false,
            errorMessage: "",
            successMessage: ""
        }
    }
    componentDidMount = () => {

        const {
            match: { params },
        } = this.props;
        this.setState({
            service: params.index,
            serviceId: params.id
        });

        if ((params.index != null) && params.index != 0) {

            let flowDescription = `I would like to start using the service because...`;
            this.setState({ flowDescription: flowDescription });
        }

        if (CommonValues.GetToken() != null) {
            this.getUserDetailsById();
            this.setState({ userInfoEditable: false });
        } else {
            this.setState({ userInfoEditable: true });
        }
        scroll.scrollToTop();
    }


    getUserDetailsById = () => {
        this.setState({
            loading: true
        })
        var token = CommonValues.GetToken();

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_UserService_Url}/api/user/userdetails`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                this.setState({
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    workEmail: response.data.workEmail,
                    phoneNumber: response.data.phoneNumber,
                    loading: false
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                console.log(error)
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.ClearValues();
                        window.location.reload();
                    }
                    else {
                        this.setState({
                            errorMessage: "Unknown error while getting the logged in user!!"
                        });
                    }
                } else {
                    this.setState({
                        errorMessage: "Unknown error while getting the logged in user!!"
                    })
                }
            });
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onFirstNameChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onLastNameChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onWorkemailChange = (e) => {
        if (e.target.value.length > 500) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onContactNumberChange = (e) => {
        const re = /^[+-]?\d*(?:[.,]\d*)?$/;
        if (re.test(e.target.value)) {
            if (e.target.value.length > 10) {
                return;
            }
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    };

    onDescChange = (e) => {
        if (e.target.value.length > 150) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    checkValidation = () => {
        let formIsValid = true;

        this.setState({
            errormessageFirstName: "",
            errormessageLastName: "",
            errormessageWorkEmail: "",
            errormessageDescription: "",
            errormessageSelectService: "",
            errorMessagePhoneNumber: "",
            errorMessage: "",
            successMessage: ""
        });

        if (this.state.firstName == "") {
            formIsValid = false;
            this.setState({
                errormessageFirstName: "Please enter first name"
            });
            scroll.scrollToTop();
        }
        if (this.state.lastName == "") {
            formIsValid = false;
            this.setState({
                errormessageLastName: "Please enter last name"
            });
            scroll.scrollToTop();
        }
        if (this.state.workEmail == "") {
            formIsValid = false;
            this.setState({
                errormessageWorkEmail: "Please enter email"
            });
            scroll.scrollToTop();
        } else {
            const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
            if (regex.test(this.state.workEmail) === false) {
                formIsValid = false;
                this.setState({
                    errormessageWorkEmail: "Please enter valid email"
                });
                scroll.scrollToTop();

            }
        }

        if (this.state.flowDescription == "") {
            formIsValid = false;
            this.setState({
                errormessageDescription: "Please enter description"
            });
            scroll.scrollToTop();
        }
        if (CommonValues.GetToken() == null) {
            if (this.state.termCondition !== true) {
                formIsValid = false;
                this.setState({
                    errorMessage: "Please accept terms and conditions",
                });
                scroll.scrollToTop();
            }
        }
        if (this.state.phoneNumber != null && this.state.phoneNumber != "") {
            if (this.state.phoneNumber.length < 10) {
                formIsValid = false;
                this.setState({
                    errorMessagePhoneNumber: "Please enter valid phone number."
                })
                scroll.scrollToTop();
            }
        }

        return formIsValid;
    }

    onWorkflowSubmitClicked = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Startworkflow Page',
            action: 'Start Workflow clicked for service : ' + this.state.service,
        });

        if (this.checkValidation()) {
            var companyName = CommonValues.GetCompanyName();
            if (companyName != null && companyName != "null") {

                this.addNewWorkflow(); //already login and user verified
            }
            else {
                this.registerAndAddWorkflow();
            }
        }
    }
    selectCheckbox = () => {
        this.setState({
            termCondition: !this.state.termCondition
        })
    }
    registerAndAddWorkflow = () => {
        let serviceName = "";
        this.setState({
            submitLoading: true
        });

        serviceName = this.state.service
        let showTermsCheckbox = true;


        if (CommonValues.GetToken() != null) {
            //Company does not exists but token exists
            showTermsCheckbox = false;
        }

        var data = JSON.stringify({
            "FirstName": this.state.firstName,
            "LastName": this.state.lastName,
            "WorkEmail": this.state.workEmail,
            "PhoneNumber": this.state.phoneNumber,
            "ServiceName": serviceName,
            "WorkflowName": this.state.flowName,
            "Description": this.state.flowDescription,
            "ServiceId": this.state.serviceId,
            "AcceptTerms": showTermsCheckbox ? this.state.termCondition : true
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/Marketplace/registerworkflowinfo`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    submitLoading: false,
                    errorMessage: "",
                    successMessage: "Thank you, Your request has been successfully submitted and one of our representatives will contact you shortly."
                });
                scroll.scrollToTop();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    submitLoading: false,
                });
                let errorMessage;
                if (error.response != null) {
                    if (error.response.status == 409) {
                        errorMessage = "Thanks, your request is already in progress and our representative will get back to you"
                    }
                    else {

                        errorMessage = "Unknown error while starting workflow!!"
                    }
                }
                else {
                    errorMessage = "Unknown error while starting workflow!!"
                }
                this.setState({
                    errorMessage: errorMessage,
                    successMessage: ''
                });

            });
    }

    onRegisterClick = (e) => {
        this.props.history.push("/register-seller")
        e.preventDefault();
    }

    onLoginLinkClicked = (e) => {
        this.props.history.push("/login");
        e.preventDefault();
    }

    addNewWorkflow = () => {
        let serviceName = "";
        this.setState({
            submitLoading: true
        });


        serviceName = this.state.service

        var token = CommonValues.GetToken();

        var data = JSON.stringify({
            "FirstName": this.state.firstName,
            "LastName": this.state.lastName,
            "WorkEmail": this.state.workEmail,
            "PhoneNumber": this.state.phoneNumber,
            "ServiceName": serviceName,
            "WorkflowName": this.state.flowName,
            "Description": this.state.flowDescription,
            "ServiceId": this.state.serviceId,
            "AcceptTerms": true
        });

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/services/insertworkflowinfo`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.setState({
                    successMessage: "Thank you, Your request has been successfully submitted and one of our representatives will contact you shortly.",
                    submitLoading: false
                });
                scroll.scrollToTop();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    submitLoading: false,
                });
                let errorMessage
                if (error.response != null) {
                    if (error.response.status == 409) {
                        errorMessage = "Thanks, your request is already in progress and our representative will get back to you";
                    }
                    else if (error.response.status == 401) {
                        CommonValues.ClearValues();
                        window.location.reload();
                    }
                    else {
                        errorMessage = "Unknown error while starting workflow!!"
                    }
                }
                else {
                    errorMessage = "Unknown error while starting workflow!!"
                }
                this.setState({
                    errorMessage: errorMessage,
                    successMessage: ''
                });

            });
    }

    renderHeader() {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-list" value="bullet" type="button"><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button>
            </span>
        );
    }
    ChangeShowSidebar = (val) => {
        this.setState({
            showSidebar: val
        })
    }
    render() {

        return (
            <>

                <MainHeader
                    showSidebar={this.state.showSidebar}

                ></MainHeader>
                <div className="wrapper-new align-items-stretch wf-wrapper">

                    {
                        <Sidebar
                            showSidebar={this.ChangeShowSidebar}
                            showFilterLogo={this.state.showFilterLogo}
                        ></Sidebar>
                    }
                    <div className="row header-margin bg-community-banner w-100 wf-row wf-header position-relative">
                        <div className="leftbg app-bg1  col-1 pl-0 pr-0  ">

                        </div>
                        <div className="midbg col-10  pr-0 wf-midbg ">
                            <div className="continer2 text-left  wfcontiner" >
                                <MiddleHeader heading1="Welcome to InsuranceGIG" heading2="Connecting Buyers and Sellers through Insurance Microservices"></MiddleHeader>

                                <div className="p-3 mid-sec float-left  account-content">
                                    <div className="  ml1 text-center errormsgforaccountpage">
                                        <IgigErrorMessage errorMessage={this.state.errorMessage}></IgigErrorMessage>
                                        <IgigSuccessMessage message={this.state.successMessage}></IgigSuccessMessage>
                                    </div>
                                    <div className="mid-sec-inner pt-3">
                                        <div className="col-md-4 float-left mid1-right text-left"><b>Start Workflow</b></div>
                                        <div className="col-md-8 float-left mid1-left text-center">
                                            {
                                                CommonValues.GetToken() == null ?
                                                    <p className="registration-p" >Don't have an account ? <a href="" onClick={(e) => this.onRegisterClick(e)}><b className="registration-b">Register</b></a></p>
                                                    :
                                                    <p className="registration-p" >&emsp;<a href="" onClick={(e) => this.onLoginLinkClicked(e)}><b className="registration-b"></b></a></p>
                                            }
                                        </div>
                                    </div>
                                    {this.state.loading ?
                                        <IgigLoadingIcon></IgigLoadingIcon>
                                        :
                                        <>
                                            <div className="mid-sec-inner">
                                                <label>Service <span className="text-danger">*</span></label>
                                                <IgigInputBox
                                                    errorMessage=""
                                                    type="text"
                                                    id="flowName"
                                                    name="service"
                                                    onChange={this.onChange}
                                                    value={this.state.service}
                                                    disabled="true"
                                                ></IgigInputBox><br />
                                            </div>
                                            <div className="mid-sec-inner">
                                                <label>First Name <span className="text-danger">*</span></label>
                                                <IgigInputBox
                                                    errorMessage={this.state.errormessageFirstName}
                                                    type="text"
                                                    id="firstName"
                                                    name="firstName"
                                                    onChange={this.onFirstNameChange}
                                                    value={this.state.firstName}
                                                    disabled={!this.state.userInfoEditable}
                                                ></IgigInputBox>
                                                <br />
                                            </div>

                                            <div className="mid-sec-inner">
                                                <label>Last Name <span className="text-danger"> *</span></label>
                                                <IgigInputBox
                                                    errorMessage={this.state.errormessageLastName}
                                                    type="text"
                                                    id="lastName"
                                                    name="lastName"
                                                    onChange={this.onLastNameChange}
                                                    value={this.state.lastName}
                                                    disabled={!this.state.userInfoEditable}
                                                ></IgigInputBox>

                                                <br />
                                            </div>
                                            <div className="mid-sec-inner">
                                                <label>Work Email <span className="text-danger">*</span></label>
                                                <IgigInputBox
                                                    errorMessage={this.state.errormessageWorkEmail}
                                                    type="text"
                                                    id="workEmail"
                                                    name="workEmail"
                                                    onChange={this.onWorkemailChange}
                                                    value={this.state.workEmail}
                                                    disabled={!this.state.userInfoEditable}
                                                ></IgigInputBox>

                                                <br />
                                            </div>
                                            <div className="mid-sec-inner">
                                                <label>Contact Number</label>
                                                <IgigInputBox
                                                    errorMessage={this.state.errorMessagePhoneNumber}
                                                    type="text"
                                                    id="flowName"
                                                    name="phoneNumber"
                                                    onChange={this.onContactNumberChange}
                                                    value={this.state.phoneNumber}
                                                    disabled={!this.state.userInfoEditable}
                                                ></IgigInputBox>

                                                <br />
                                            </div>
                                            <div className="mid-sec-inner">
                                                <label>Workflow Description <span className="text-danger">*</span></label>
                                                <IgigTextarea
                                                    errorMessage={this.state.errormessageDescription}

                                                    // className={this.state.errormessageDescription ? "inputValidation form-control minput" : "form-control minput"}
                                                    type="text"
                                                    id="flowDescription"
                                                    name="flowDescription"
                                                    onChange={this.onDescChange}
                                                    value={this.state.flowDescription}
                                                    maxlength="2000"
                                                    rows={5}
                                                ></IgigTextarea>
                                                {/* <div className="validation-error-msg">
                                                    {this.state.errormessageDescription.length > 0 ? (
                                                        <span>{this.state.errormessageDescription}</span>
                                                    ) : null}
                                                </div> */}
                                                <br />
                                            </div>
                                            {
                                                CommonValues.GetToken() == null ?
                                                    <div className="mid-sec-inner ml1">
                                                        <p><input type="checkbox" name="termCondition" value={this.state.termCondition} className="termconditioninput" onClick={this.selectCheckbox} /> I accept <a href="/terms" target="_blank" >terms and conditions</a>
                                                            <br /><br />
                                                            By submitting your request you are requesting an InsuranceGIG<br /> flow expert to contact you
                                                        </p>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            <div className="mid-sec-inner ml1 text-center">
                                                {this.state.submitLoading ?
                                                    <IgigLoadingIcon></IgigLoadingIcon>
                                                    :
                                                    <IgigPrimaryBtn
                                                        onClick={this.onWorkflowSubmitClicked}
                                                        text="Submit"
                                                    ></IgigPrimaryBtn>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="rightbg app-bg2 col-md-2 col-lg-3 col-1 pl-0 pr-0 position-absolute">
                        </div>
                    </div>
                </div>
                <ListingFooter></ListingFooter>
            </>
        )
    }
}
export default withRouter(StartWorkflow);
