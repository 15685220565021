import React, { Component } from 'react'
import { Message } from 'primereact/message';

export default class IgigSuccessMessage extends Component {
    render() {
        return (
            <>
                {
                    this.props.message != null && this.props.message.length > 0 ?
                        <div className="p-col-12 p-md-3">
                            <Message severity="success" text={this.props.message} className="textdemo" />
                        </div>
                        : null
                }
            </>

        )
    }
}
