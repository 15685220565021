import React, { Component } from 'react';
import { MultiSelect } from 'primereact/multiselect';

export default class IgigMultiselect extends Component {
    render() {
        return (
            <MultiSelect
            className={this.props.errorMessage != null && this.props.errorMessage.length > 0 ? "inputValidation " : ""}
             value={this.props.value}
                options={this.props.options}
                onChange={this.props.onChange}
                optionLabel={this.props.optionLabel}
                placeholder={this.props.placeholder}
                maxSelectedLabels={this.props.maxSelectedLabels}
                style={{ width: "100%" }} />
        );
    }
}
