import React, { Component } from 'react';
import CommonValues from '../Common/util';
import { withRouter } from 'react-router-dom';
import MarketplaceImg from '../../src/assets/images/new-images/Layout 3.svg';
import DashboardImg from '../../src/assets/images/new-images/dashboard.svg';
import CompanyImg from '../../src/assets/images/new-images/company-profile.svg';
import AccountUser from '../../src/assets/images/new-images/account-users.svg';
import Settings from '../../src/assets/images/new-images/settings.svg';
import WorkflowImg from '../../src/assets/images/new-images/workflow.svg';
import ReportImg from '../../src/assets/images/new-images/reports.svg';
import strokeImg from '../../src/assets/images/new-images/reqservice.svg';
import CalenderImg from '../../src/assets/images/new-images/calendar.svg';
import VideoImg from '../../src/assets/images/new-images/youtube (1).svg';
import CommunityImg from '../../src/assets/images/new-images/flag1.svg';
import { Link } from "react-router-dom"; //for gitlab
import ReactGA from 'react-ga';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarCollapseBtn: false,
            selectedItem: "",
            avatarName: "",
        }
    }

    componentDidMount = () => {
        localStorage.setItem('DemoSidebar', this.state.sidebarCollapseBtn)
     
        var x=   localStorage.getItem('SidebarValue')
        if(x=='true'){
            this.setState({
                sidebarCollapseBtn:true
            })
        }
        else{
            this.setState({
                sidebarCollapseBtn:false
            })
        }
    }
    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.showSearchBar != this.props.showSearchBar) {
            if (this.props.showSearchBar) {
                this.setState({
                    sidebarCollapseBtn: false
                });
            }
        }
    }

    onLogoutClick = (e) => {
        CommonValues.Logout();
    }

    onMarketplaceClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Landing page',
            action: 'Click on appmarket',
        });
        this.props.history.push("/")
        e.preventDefault();
    }
    onDashboardClick = (e) => {
        this.props.history.push("/dashboard")
        e.preventDefault();
    }

    onCommunityClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Community dashboard page',
            action: 'Click on community dashboard page',
        });
        window.location = "/community/dashboard";
        e.preventDefault();
    }
    onBlogClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Community blog page',
            action: 'Click on blog page',
        });
        window.location = "/blogs";
        e.preventDefault();
    }
    onEventsClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Community event page',
            action: 'Click on event page',
        });
        window.location = "/community/events";
        e.preventDefault();
    }

    onRequestServiceClicked = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'request service page',
            action: 'Click on request service page',
        });
        this.props.history.push('/requestservice');
        e.preventDefault();
    }
    onVideoClick = (e) => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Video page',
            action: 'Click on video page',
        });
        this.props.history.push('/videos');
        e.preventDefault();
    }

    onMyWorkflowClick = (e) => {  
        this.props.history.push("/myworkflows")
        e.preventDefault();
    }
    onReportsClick = (e) => {
        this.props.history.push("/sellerreport")
        e.preventDefault();
    }
    onMyServiceClick = (e) => {
        this.props.history.push("/myservices")
        e.preventDefault();
    }

    onMyProfiledClick = (e) => {
        this.props.history.push("/profile")
        e.preventDefault();
    }
    onAccountUserClick = (e) => {
        this.props.history.push("/users")
        e.preventDefault();
    }
    onHover = (selectedItem) => {
        this.setState({ selectedItem: selectedItem });
    }

    onCompanyClick = (e) => {

        this.props.history.push("/company")
        e.preventDefault();
    }
    showHideBtn = () => {
        localStorage.setItem('DemoSidebar', !this.state.sidebarCollapseBtn)
        localStorage.setItem('SidebarValue', !this.state.sidebarCollapseBtn)
        this.setState({
            sidebarCollapseBtn: !this.state.sidebarCollapseBtn
        })

        this.props.showSidebar(!this.state.sidebarCollapseBtn)
        if (this.props.showFilterLogo) {
            this.setState({
                // sidebarCollapseBtn: false
            })
        }
       

    }
    onLoginClick = (e) => {
        this.props.history.push("/login")
        e.preventDefault();
    }
    onRegisterClick = (e) => {
        this.props.history.push("/register-seller")
        e.preventDefault();
    }

    render() {

        return (
            <nav id="sidebar-new" className={this.state.sidebarCollapseBtn ? "desktopsidebar" : "active desktopsidebar "}>
                <ul className="list-unstyled components mb-5 " >
                    <li className={this.props.pageName == "marketplace"
                        ? " active" : ""} onClick={this.onMarketplaceClick} onMouseOver={() => this.onHover('marketplace')} data-toggle="tooltip" data-placement="bottom" title="AppMarket">
                        <a href="/" onClick={this.onMarketplaceClick}>
                            <img src={MarketplaceImg} />
                            <span>AppMarket</span>
                        </a>
                    </li>
                    <li className={this.props.pageName == "dashboard"
                        ? " active" : ""} onClick={this.onCommunityClick} onMouseOver={() => this.onHover('Community')} data-toggle="tooltip" data-placement="bottom" title="Community">
                        <Link to={`/community/dashboard`} className="sidebar-link">
                            <img src={CommunityImg} />
                            <span>Community</span>
                        </Link>
                    </li>
                    <li className={this.props.pageName == "Blog"

                        ? " active" : ""} onClick={this.onBlogClick} onMouseOver={() => this.onHover('Blog')} data-toggle="tooltip" data-placement="bottom" title="Blog">
                        <a href="/blogs" >
                            <img src={ReportImg} />
                            <span>Blog</span></a>
                    </li>
                    <li className={this.props.pageName == "events"
                        ? " active" : ""} onClick={this.onEventsClick} onMouseOver={() => this.onHover('Events')} data-toggle="tooltip" data-placement="bottom" title="Events">
                        <Link to={`/community/events`} className="sidebar-link">
                            <img src={CalenderImg} />
                            <span>Events</span>
                        </Link>
                    </li>
                    <li className={this.props.pageName == "videos"
                        ? " active" : ""} onClick={this.onVideoClick} onMouseOver={() => this.onHover('video')} data-toggle="tooltip" data-placement="bottom" title="Videos">
                        <a href="/" onClick={this.onVideoClick}>
                            <img src={VideoImg} />
                            <span>Videos</span>
                        </a>
                    </li>
                    {
                        CommonValues.GetToken() != null
                            ?
                            <>
                                {
                                    CommonValues.GetCompanyName() !== "null" && CommonValues.GetHasGIGMarket() =="false" ?
                                        <li className={this.props.pageName == "Dashboards" ? "active" : "sidebarautorizeitem"} onClick={this.onDashboardClick} onMouseOver={() => this.onHover('Dashboards')} data-toggle="tooltip" data-placement="bottom" title="Dashboard" >
                                            <a href="/" >
                                                <img src={DashboardImg} />
                                                <span>Dashboard</span></a>
                                        </li>
                                        :
                                        null}

                                <li className={this.props.pageName == "CompanyProfile" ? " active" : "sidebarautorizeitem"} onClick={this.onCompanyClick} onMouseOver={() => this.onHover('CompanyProfile')} data-toggle="tooltip" data-placement="bottom" title="Company">
                                    <a href="/company" >
                                        <img src={CompanyImg} />
                                        <span>Company</span></a>
                                </li>

                                {
                                    CommonValues.GetCompanyName() !== "null" && CommonValues.GetHasGIGMarket() =="false" ?
                                        <>
                                            {/* <li className={
                                                this.props.pageName == "UserProfile"
                                                    ? " active" : "sidebarautorizeitem"} onClick={this.onMyProfiledClick} onMouseOver={() => this.onHover('UserProfile')} data-toggle="tooltip" data-placement="bottom" title="User Profile">
                                                <a href="/profile" >
                                                    <img src={AccountUser} />
                                                    <span>User Profile</span></a>
                                            </li> */}

                                            <li className={
                                                this.props.pageName == "accountuser"
                                                    ? " active" : "sidebarautorizeitem"} onClick={this.onAccountUserClick} onMouseOver={() => this.onHover('accountuser')} data-toggle="tooltip" data-placement="bottom" title="Account Users">
                                                <a href="/profile" >
                                                    <img src={AccountUser} />
                                                    <span>Account users</span></a>
                                            </li>


                                            <li className={
                                                this.props.pageName == "Service"
                                                    ? "active" : "sidebarautorizeitem"} onClick={this.onMyServiceClick} onMouseOver={() => this.onHover('Service')} data-toggle="tooltip" data-placement="bottom" title="Services">
                                                <a href="/myservices">
                                                    <img src={Settings} />
                                                    <span>Services</span></a>
                                            </li>

                                            <li className={
                                                this.props.pageName == "Workflow"
                                                    ? " active" : "sidebarautorizeitem"} onClick={this.onMyWorkflowClick} onMouseOver={() => this.onHover('Workflow')} data-toggle="tooltip" data-placement="bottom" title="Workflows">
                                                <a href="/myworkflows" >
                                                    <img src={WorkflowImg} />
                                                    <span>Workflows</span></a>
                                            </li>

                                            {CommonValues.GetHasGIGMarket() == "false" ?
                                                <li className={
                                                    this.props.pageName == "Sellerreport"
                                                        ? " active" : "sidebarautorizeitem"} onClick={this.onReportsClick} onMouseOver={() => this.onHover('Sellerreport')} data-toggle="tooltip" data-placement="bottom" title="Reports" >
                                                    <a href="/sellerreport" >
                                                        <img src={ReportImg} />
                                                        <span>Reports</span></a>
                                                </li>
                                                :
                                                null}
                                        </>
                                        :
                                        null
                                }
                            </>
                            :
                            null
                    }

                    {CommonValues.GetHasGIGMarket() == 'true' ||  CommonValues.GetToken() == null?
                        <li className={this.props.pageName == "Request service"
                            ? " active req-active req" : "req"}
                            onClick={this.onRequestServiceClicked}
                            onMouseOver={() => this.onHover('Request service')}
                            data-toggle="tooltip" data-placement="bottom"
                            title="Request Service" style={{ background: "#FFFACC" }}
                            id={
                                CommonValues.GetToken() != null ? "req-after-login" : "req-before-login"}
                        >
                            <Link
                                to={`/requestservice`}
                                className="sidebar-link">
                                <img src={strokeImg} />
                                <span>Request Service</span>
                            </Link>

                        </li>
                        :
                        null
                    }


                </ul>


                <>
                    {this.state.sidebarCollapseBtn ?
                        <button type="button" id="sidebarCollapse" className="btn  btn-collapse header-btn" onClick={this.showHideBtn}>
                            <i className="fa fa-angle-left" />
                        </button>
                        :
                        <button type="button" id="sidebarCollapse" className="btn  btn-collapse header-btn" onClick={this.showHideBtn}>
                            <i className="fa fa-angle-right" />
                        </button>
                    }
                </>

            </nav>
        )
    }
}
export default withRouter(Sidebar);
