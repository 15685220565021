import React, { Component } from 'react'
import ListingFooter from '../Common/ListingFooter';
import MainHeader from '../OtherCommonPages/MainHeader'
import Sidebar from '../OtherCommonPages/Sidebar'
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import IgigErrorMessage from '../Components/IgigErrorMessage';
import IgigMultiselect from '../Components/IgigMultiselect';
import IgigCalender from '../Components/IgigCalender';
import IgigInfoMessage from '../Components/IgigInfoMessage';
import IgigBlueSecondaryBtn from '../Components/IgigBlueSecondaryBtn';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CanvasJSReact from '../assets/canvasjs.react';
import CommonValues from '../Common/util';
import moment from 'moment'
import { Redirect } from "react-router-dom";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

export default class SellerReport extends Component {

    constructor(props) {
        super(props);
        const previousMonth = new Date();
        previousMonth.setMonth(previousMonth.getMonth() - 1)
        this.state = {
            showSidebar: true,
            serviceProviderLoading: false,
            sellerServiceProvider: "",
            sellerserviceProviderList: [],
            sellerServiceProviderIds: [],
            fromDate: previousMonth,
            toDate: new Date(),
            totalRequest: 0,
            totalCostShow: 0,
            totalIgFeeShow: 0,
            totalFinalCostShow: 0,
            serviceProviderError: "",
        }
    }
    componentDidMount = () => {
        this.getServiceProviderList();

    }
    onSellerServiceProviderChange = (e) => {
        this.setState({ sellerServiceProvider: e.value });
        this.setState({
            sellerServiceProviderIds: e.value.map(element => element.id)
        });
    }
    onFromDateChage = (e) => {
        this.setState({ fromDate: e.value });
    }
    onToDateChage = (e) => {
        this.setState({ toDate: e.value });
    }
    onShowHideSidebar = () => {
        this.setState({
            showSidebar: !this.state.showSidebar
        })
    }
    ChangeShowSidebar = (val) => {
        this.setState({
            showSidebar: val,
            showSearchBar: false
        });
    }
    closeNav = () => {
        this.setState({
            showSidebar: false,
        })
    }
    getServiceProviderList = () => {
        this.setState({
            serviceProviderLoading: true
        });

        var axios = require('axios');
        var token = CommonValues.GetToken();

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Report_Url}/api/report/sellerserviceprovider`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        axios(config)
            .then((response) => {
                if (response.data.length > 0) {
                    const SellerServiceproviderlist = [];
                    response.data.forEach(element => {
                        SellerServiceproviderlist.push(element.id)
                    })

                    this.setState({
                        sellerserviceProviderList: response.data,
                        sellerServiceProviderIds: SellerServiceproviderlist,
                        sellerServiceProvider: response.data,
                        serviceProviderLoading: false
                    });

                }
                else {
                    this.setState({
                        serviceProviderLoading: false
                    });
                }
                this.onSearchClick();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    serviceProviderLoading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                }
            });

    }
    isValid = () => {
        let valid = true;
        this.setState({
            serviceProviderError: "",
            fromDateError: "",
            toDateError: "",
            emptyDateError: "",
            invalidDateError: ""

        });

        if (this.state.sellerServiceProviderIds == null || this.state.sellerServiceProviderIds.length == 0) {
            this.setState({
                serviceProviderError: 'Please select service'
            });

            valid = false;
            return valid
        }

        if (this.state.fromDate == null || this.state.fromDate == '') {
            this.setState({
                fromDateError: 'Please select from date',
                emptyDateError: 'Please select from date'
            });

            valid = false;
            return valid
        }
        if (this.state.toDate == null || this.state.toDate == '') {
            this.setState({
                toDateError: 'Please select to date',
                emptyDateError: 'Please select to date'
            });
            valid = false;
            return valid
        }
        if (this.state.fromDate > this.state.toDate) {
            this.setState({
                invalidDateError: 'To date must be greater than from date'
            });
            valid = false;
            return valid
        }
        return valid
    }
    onSearchClick = () => {
        if (this.isValid()) {
            this.setState({
                pageLoading: true
            });

            var axios = require('axios');
            var token = CommonValues.GetToken();
            var data = JSON.stringify({
                "serviceproviderIds": this.state.sellerServiceProviderIds,
                "fromDate": moment(this.state.fromDate).format('yyyy-MM-DD'),
                "toDate": moment(this.state.toDate).format('yyyy-MM-DD')
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_Report_Url}/api/report/sellerusage`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then((response) => {

                    let chartDataList = [];
                    if (response.data.listChartData.length > 1) {
                        response.data.listChartData.forEach(element => {
                            let dataPoints = [];

                            element.dataPoints.forEach(d => {
                                dataPoints.push({
                                    y: d.y,
                                    x: new Date(d.x)
                                });
                            });
                            chartDataList.push({
                                type: "stackedColumn",
                                showInLegend: "true",
                                legendText: element.legendText,
                                dataPoints: dataPoints,

                            });

                        });
                    } else {
                       
                        let unique = this.findUnique(response.data.listData, d => d.buyerName);                        

                          Array.from(unique,item => {
                            let dataPoints = [];
                            Array.from(response.data.listData,element => {
                                if (item.buyerName == element.buyerName) {
                                    dataPoints.push({
                                        y: element.numberOfRequest,
                                        x: new Date(element.usageDate)
                                    });
                                }
                            })
                            chartDataList.push({
                                type: "stackedColumn",
                                name: item.buyerName,
                                showInLegend: true,
                                dataPoints: dataPoints,

                            });
                        });
                    }

                    let totalrequestmade = 0;
                    response.data.listData.forEach(element => {
                        totalrequestmade = totalrequestmade + parseInt(element.numberOfRequest);
                    });

                    let totalCost = 0;
                    response.data.listData.forEach(element => {
                        totalCost = totalCost + (element.totalCost);
                    });
                    totalCost = totalCost.toFixed(2);
                    let totalIgFee = 0;
                    response.data.listData.forEach(element => {
                        totalIgFee = totalIgFee + (element.insurancegigFee);
                    });
                    totalIgFee = totalIgFee.toFixed(2);
                    let totalFinalCost = 0;
                    response.data.listData.forEach(element => {
                        totalFinalCost = totalFinalCost + (element.finalCost);
                    });
                    totalFinalCost = totalFinalCost.toFixed(2);

                    this.setState({
                        sellerList: response.data.listData,
                        dataListForChart: chartDataList,
                        totalRequest: totalrequestmade,
                        totalCostShow: totalCost,
                        totalIgFeeShow: totalIgFee,
                        totalFinalCostShow: totalFinalCost,
                        pageLoading: false,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        pageLoading: false
                    });
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                    }
                });
        }
    }
    findUnique = (arr, predicate) => {
        var found = {};
        arr.forEach(d => {
            found[predicate(d)] = d;
        });
        return Object.keys(found).map(key => found[key]);
    }
    onExportClick = () => {
        if (this.isValid()) {

            this.setState({
                pageLoading: true
            });

            var axios = require('axios');
            var token = CommonValues.GetToken();
            var data = JSON.stringify({
                "serviceproviderIds": this.state.sellerServiceProviderIds,
                "fromDate": moment(this.state.fromDate).format('yyyy-MM-DD'),
                "toDate": moment(this.state.toDate).format('yyyy-MM-DD')
            })
            var config = {
                method: 'post',
                responseType: 'blob', // important
                url: `${process.env.REACT_APP_Report_Url}/api/report/sellerusageexport`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then((response) => {
                    this.setState({
                        pageLoading: false
                    });
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'usage.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        pageLoading: false
                    });
                    if (error.response != null) {
                        if (error.response.status == 401) {
                            CommonValues.Logout();
                        }
                    }
                });
        }
    }
    formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    balanceBodyTemplate = (rowData) => {
        if (rowData.finalCost != null) {
            return this.formatCurrency(rowData.finalCost);
        }
    }

    feeBodyTemplate = (rowData) => {
        if (rowData.insurancegigFee != null) {
            return this.formatCurrency(rowData.insurancegigFee);
        }
    }

    totalCostBodyTemplate = (rowData) => {
        if (rowData.totalCost != null) {
            return this.formatCurrency(rowData.totalCost);
        }
    }


    render() {
        const options = {
            animationEnabled: true,
            exportEnabled: false,
            dataPointWidth: 40,
            title: {
                text: ""
            },
            toolTip: {
				shared: true,
				reversed: true
			},
            axisX: {
                title: "Dates",
                valueFormatString: "DD.MM.YY",
                interval: 1,
                intervalType: "day",
                labelFontColor: "#C5B0DB",
            },
            axisY: {
                title: "No. of Requests",
                labelFontColor: "#2A2A2A",
            },
            data: this.state.dataListForChart
        }

        let authValue = CommonValues.CheckPermissionAndCompany();
        if (authValue == 401) {
            return <Redirect to="/login" />
        } else if (authValue == 402) {
            return <Redirect to="/company" />
        }
        return (
            <>
                <MainHeader
                    showSidebar={this.state.showSidebar}
                    pageName="Sellerreport"
                ></MainHeader>
                <div className="wrapper-new d-flex align-items-stretch">
                    <Sidebar
                        showSidebar={this.ChangeShowSidebar}
                        showFilterLogo={this.state.showFilterLogo}
                        showSearchBar={this.state.showSearchBar}
                        pageName="Sellerreport"
                    ></Sidebar>
                    <div id="content" className="content">
                        <div className=" container-fluid bg-community-banner pl-0 pr-0 position-relative report-banner">
                            <div className="bg-img-banner-text">
                                <div className="row align-items-center justify-content-center position-relative">
                                </div>
                            </div>
                        </div>

                        <div className="mid-container mid-container-dashboard" style={{ minHeight: "65vh" }}>
                            <div className="admin-dashboard-position pb-5 ">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <h4 className='text-white '>Services Usage Report</h4>
                                        {
                                            this.state.pageLoading ?
                                                <div style={{ minHeight: "400px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <IgigLoadingIcon></IgigLoadingIcon>
                                                </div>
                                                :
                                                <>
                                                    <div className="container-fluid mb-2">
                                                        {
                                                            this.state.serviceProviderError != null && this.state.serviceProviderError.length > 0 ?
                                                                <IgigErrorMessage errorMessage={this.state.serviceProviderError}></IgigErrorMessage>
                                                                : null
                                                        }
                                                        {
                                                            this.state.fromDateError != null && this.state.fromDateError.length > 0
                                                                ||
                                                                this.state.toDateError != null && this.state.toDateError.length > 0
                                                                ?
                                                                <IgigErrorMessage errorMessage={this.state.emptyDateError}></IgigErrorMessage>
                                                                : null
                                                        }
                                                        {
                                                            this.state.invalidDateError != null && this.state.invalidDateError.length > 0 ?
                                                                <IgigErrorMessage errorMessage={this.state.invalidDateError}></IgigErrorMessage>
                                                                : null
                                                        }
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="card mb-4">
                                                                <div className="p-2">
                                                                    <form className="mb-0">
                                                                        <div className="form-row mobile-grid-form align-items-center">
                                                                            <div className="col-xs-12 col-md-12 col-lg-6 align-items-center mobile-reports">
                                                                                <h6 className="mb-0 pr-3">Select Service(s)</h6>
                                                                                {
                                                                                    this.state.serviceProviderLoading ?
                                                                                        <IgigLoadingIcon></IgigLoadingIcon>
                                                                                        :
                                                                                        <div className="form-group mb-0 report-form" style={{ width: "80%" }}>
                                                                                            <IgigMultiselect
                                                                                                value={this.state.sellerServiceProvider}
                                                                                                options={this.state.sellerserviceProviderList}
                                                                                                onChange={this.onSellerServiceProviderChange}
                                                                                                optionLabel="name"
                                                                                                placeholder="Select a Service Provider"
                                                                                                maxSelectedLabels={2}
                                                                                                errorMessage={this.state.serviceProviderError}
                                                                                            >
                                                                                            </IgigMultiselect>
                                                                                        </div>
                                                                                }
                                                                            </div>

                                                                            <div className="col-xs-12 col-md-12 col-lg-4 mobile-reports  report-cal seller-report-cal ">
                                                                                <label htmlFor="inputEmail4" className="mb-0 pr-2">From</label>
                                                                                <div className="form-group mb-0 pr-2 ">
                                                                                    <IgigCalender
                                                                                        value={this.state.fromDate}
                                                                                        onChange={this.onFromDateChage}
                                                                                        errorMessage={this.state.fromDateError}
                                                                                    ></IgigCalender>
                                                                                </div>
                                                                                <label htmlFor="inputEmail4" className="mb-0 pr-2">To</label>
                                                                                <div className="form-group mb-0 pr-2 ">

                                                                                    <IgigCalender
                                                                                        value={this.state.toDate}
                                                                                        onChange={this.onToDateChage}
                                                                                        errorMessage={this.state.toDateError}
                                                                                    ></IgigCalender>

                                                                                </div>
                                                                            </div>
                                                                            <div className="report-btn col-xs-12 col-md-12 col-lg-2 report-div">
                                                                                <IgigBlueSecondaryBtn text="Search" width="120px" float="right" onClick={this.onSearchClick}></IgigBlueSecondaryBtn>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="card mb-4 whole-card">
                                                                <div className="card-body">
                                                                    {
                                                                        this.state.totalRequest == 0 ?
                                                                            <IgigInfoMessage message="No requests found" />
                                                                            :
                                                                            <div className={this.state.showSidebar ? "canvas-width" : ""}>
                                                                                <div className="row mb-6">
                                                                                    <div className="col-md-3">
                                                                                        <div className="reports-charts ml-5">
                                                                                            <div className="mr-1  p-3 icon">
                                                                                                <i className="fa fa-cog" />
                                                                                            </div>
                                                                                            <div className="pl-2">
                                                                                                <h6 className=" mb-0 font-weight-bolder">{this.state.totalRequest}</h6>
                                                                                                <span className="text-12 text-muted">Total Requests Made</span>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-3">
                                                                                        <div className="reports-charts">
                                                                                            <div className="mr-1  p-3 icon">
                                                                                                <i className="fa fa-cog" />
                                                                                            </div>
                                                                                            <div className="pl-2">
                                                                                                <h6 className=" mb-0 font-weight-bolder">${this.state.totalCostShow}</h6>
                                                                                                <span className="text-12 text-muted">Total Cost</span>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-3">
                                                                                        <div className="reports-charts">
                                                                                            <div className="mr-1  p-3 icon">
                                                                                                <i className="fa fa-cog" />
                                                                                            </div>
                                                                                            <div className="pl-2">
                                                                                                <h6 className=" mb-0 font-weight-bolder">${this.state.totalIgFeeShow}</h6>
                                                                                                <span className="text-12 text-muted">Total InsuranceGIG Cost</span>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="col-md-3">
                                                                                        <div className="reports-charts">
                                                                                            <div className="mr-1  p-3 icon">
                                                                                                <i className="fa fa-cog" />
                                                                                            </div>
                                                                                            <div className="pl-2">
                                                                                                <h6 className=" mb-0 font-weight-bolder">${this.state.totalFinalCostShow}</h6>
                                                                                                <span className="text-12 text-muted">Final Cost</span>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <CanvasJSChart options={options} />
                                                                            </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.totalRequest == 0 ?
                                                                null :

                                                                <div className="col-md-12 ">
                                                                    <div className="card whole-card">

                                                                        <div className="  d-inline-block mb-2 report-div">
                                                                            <span className="  d-inline-block"><b>Details</b></span>
                                                                            <IgigBlueSecondaryBtn text="Export" width="120px" float="right" onClick={this.onExportClick}></IgigBlueSecondaryBtn>
                                                                        </div>
                                                                        <div className=" userlisttable report-table" style={{ fontSize: "12px" }}>
                                                                            <DataTable
                                                                                value={this.state.sellerList}
                                                                                className=" report-last-row"
                                                                                paginator
                                                                                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                                                                                rows={10} responsiveLayout="scroll" >
                                                                                <Column field="usageDate" header="Usage Date" sortable style={{ minWidth: "230px" }} ></Column>
                                                                                <Column field="buyerName" header="Buyer Name" sortable style={{ minWidth: "230px" }}></Column>

                                                                                <Column field="numberOfRequest" header="# of Requests" sortable ></Column>
                                                                                <Column field="totalCost" header="Total Cost" body={this.totalCostBodyTemplate} className="cost-alignment" sortable></Column>
                                                                                <Column field="insurancegigFee" header="InsuranceGIG Fee" body={this.feeBodyTemplate} className="cost-alignment" sortable></Column>
                                                                                <Column field="finalCost" header="Final Cost" body={this.balanceBodyTemplate} className="cost-alignment" sortable></Column>

                                                                            </DataTable>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <ListingFooter></ListingFooter>
            </>
        )
    }
}
