import React, { Component } from 'react';
import Slider from "react-slick";
import { Image } from 'primereact/image';
import ReactPlayer from 'react-player';


class ServiceDetailScreenshots extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let totalRecordsToShow = 1;
        if (this.props.width > 500) {
            if (this.props.width > 1000) {
                if (this.props.mediaList.length > 3) {
                    totalRecordsToShow = 4;
                } else {    
                    totalRecordsToShow = this.props.mediaList.length;
                }
            }
            else {
                totalRecordsToShow = 1;
            }

        }

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: totalRecordsToShow,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 1500,
            pauseOnHover: true
        };
  
        return (
            <>
          
                <Slider {...settings} className={this.props.mediaList.length>3?"" :"image-length"}>
                    
                  {
                        this.props.mediaList.map((model, index) => {
                        
                            return (
                                <div className=" image-slider image-slider-mobile" key={index}>
                                    {model.documentType == "Screenshot"?
                                        <Image src={model.documentURL} preview />
                                        :
                                        <ReactPlayer className="video-width-screen" width="160px"  
                                            height="99px" url={model.documentURL}
                                            controls={true}
                                            style={{ margin: "0 auto"}}
                                        />
                                    }
                                </div>
                            )
                        })
                    }

                </Slider>

            </>
        );
    }
}

export default ServiceDetailScreenshots;
