import React, { Component } from 'react';
import CommonValues from '../Common/util';
import { TabView, TabPanel } from 'primereact/tabview';
import ServiceOverview from '../ListingPage/ServiceOverview';
import ListingCard from '../ListingPage/ListingCard';
import { animateScroll as scroll, scroller } from 'react-scroll'
import { Redirect, withRouter } from 'react-router-dom';
import MainHeader from '../OtherCommonPages/MainHeader';
import ListingFooter from '../Common/ListingFooter';
import ServiceCommonTopBar from '../ListingPage/ServiceCommonTopBar';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import ServiceDocumentation from '../ListingPage/ServiceDocumentation';
import TryIt from '../TryIt/TryIt';
import Sidebar from '../OtherCommonPages/Sidebar';
import IgigErrorMessage from '../Components/IgigErrorMessage';
import axios from 'axios';
import ServicePreviewCommonTopBar from './ServicePreviewCommonTopBar';
class ServicePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSidebar: false,
            serviceId: "",
            service: {},
            mediaList: [],
            documentDetailsList: [],
            buildingBlockLoading: false,
            BuildingBlockServices: [],
            loading: false,
            showPriceDetails: false,
            errorMessage: "",
            innerWidth: 1024,
        }
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;

        this.setState({
            serviceId: params.index
        });
        this.updateWindowSize();
        window.addEventListener("resize", this.updateWindowSize);
        
        this.getServicePreviewDataById(params.index);
        this.getMediaByIdForPreview(params.index);
        this.getServiceDocumentByIdAndTypeForPreview(params.index);
    }
    updateWindowSize = () => {
        this.setState({ innerWidth: window.innerWidth });
    }
    onShowHideSidebar = () => {
        this.setState({
            showSidebar: !this.state.showSidebar
        })

    }
    ChangeShowSidebar = (val) => {
        this.setState({
            showSidebar: val,
            showSearchBar: false
        });
    }
    closeNav = () => {
        this.setState({
            showSidebar: false,
        })
    }
    getServicePreviewDataById = (Id) => {
        this.setState({
            loading: true
        });
        var token = CommonValues.GetToken();
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/Services/service/preview?id=${Id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },

        };

        axios(config)
            .then((response) => {
             
                this.setState({
                    service: response.data,
                    serviceName: response.data.name,
                    showPriceDetails: response.data.showPriceDetails,
                    loading: false
                });
                if (response.data == "") {
                    this.setState({ notFound: true });
                } else {
                    this.setState({ notFound: false });
                }

                if (response.data.type == "Bundled") {
                    this.getBuildingBlockForPreview(Id);
                }

            })
            .catch((error) => {
                let errorMessage = '';
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 400) {
                        this.setState({ notFound: true });
                    }
                    else if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        errorMessage = "Unknown error while getting  the service details";
                    }

                }
            });
    }
    getMediaByIdForPreview = (Id) => {
        var token = CommonValues.GetToken();
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/Services/Servicepreviewmediaid?id=${Id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },

        };
        axios(config)
            .then((response) => {
              
                this.setState({
                    mediaList: response.data,
                });
            })
            .catch((error) => {
                let errorMessage = '';
                console.log(error);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        errorMessage = "Unknown error while getting the media details";
                    }
                }
            });
    }
    handleCallback = (childData) => {
        this.setState({ name: childData })
    }
    getServiceDocumentByIdAndTypeForPreview = (id) => {
        var axios = require('axios');
        var token = CommonValues.GetToken();
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/Services/Servicepreviewservicedocuments?id=${id}&name=${""}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        };

        axios(config)
            .then((response) => {

                this.setState({
                    documentDetailsList: response.data,

                })
            })
            .catch((error) => {
                let errorMessage = '';
                console.log(error);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        errorMessage = "Unknown error while getting the documents details";
                    }
                }
            });

    }
    getBuildingBlockForPreview = (Id) => {
        var token = CommonValues.GetToken();
        this.setState({
            buildingBlockLoading: true
        });

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ServicesService_Url}/api/Services/Servicepreviewbuildingblocks?id=${Id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        };

        axios(config)
            .then((response) => {

                this.setState({
                    BuildingBlockServices: response.data,
                    buildingBlockLoading: false,
                    routeDemo: true
                })
            })
            .catch((error) => {
                let errorMessage = '';
                this.setState({
                    buildingBlockLoading: false
                });
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout();
                    }
                    else {
                        errorMessage = "Unknown error while getting the building block details";
                    }
                }
            });


    };
    render() {
        return (
            <>
                <MainHeader showSidebar={this.state.showSidebar}
                    pageName="servicedetails"
                ></MainHeader>
                <div className="wrapper d-flex align-items-stretch">

                    <Sidebar
                        showSidebar={this.ChangeShowSidebar}
                    ></Sidebar>

                    <div id="content main " className={this.state.showSidebar ? " details-content-width content mid-container detail-content" : "content mid-container detail-content"} >
                        <div className=" container-fluid service-details mobile-service-bg">
                            <IgigErrorMessage errorMessage={this.state.errorMessage}></IgigErrorMessage>
                            {/* <IgigSuccessMessage message={this.state.successMessage}></IgigSuccessMessage> */}
                            {
                                this.state.loading ?
                                    <IgigLoadingIcon margin="100px"></IgigLoadingIcon>
                                    :
                                    <>
                                        <ServicePreviewCommonTopBar service={this.state.service} serviceId={this.state.serviceId} ></ServicePreviewCommonTopBar>

                                    </>
                            }

                        </div>
                        <div className='container-fluid service-details'>
                            <div className="card">
                                <div className="card-body p-3 gig-services details-tabss">
                                    {this.state.loading ?
                                        <IgigLoadingIcon></IgigLoadingIcon>
                                        :
                                        <TabView className='preview-service' >
                                            <TabPanel header="Overview" >

                                                <ServiceOverview
                                                    parentCallback={this.handleCallback}
                                                    service={this.state.service}
                                                    apiId={this.state.serviceId}
                                                    mediaList={this.state.mediaList}
                                                    videosUrl={this.state.videosUrl}
                                                    showPriceDetails={this.state.showPriceDetails}
                                                    innerWidth={this.state.innerWidth}
                                                />
                                            </TabPanel>
                                            <TabPanel header="Pricing" >
                                                <div className="card usercard ">

                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className='col-12 PricePadding pricingheight'>
                                                                <p className='text-center prcing-link'>For pricing details, please contact us at <a href="mailto:support@insurancegig.com">support@insurancegig.com</a></p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </TabPanel>
                                            {this.state.service.showTryOut == false ?
                                                null
                                                :
                                                <TabPanel header={this.state.service.showTryOut == false ? "" : "Try it"} disabled={this.state.service.showTryOut == false} >
                                                    {
                                                        <TryIt service={this.state.service} serviceId={this.state.serviceId}>
                                                        </TryIt>
                                                    }
                                                </TabPanel>
                                            }
                                            {this.state.documentDetailsList.length != 0 ?
                                                <TabPanel header={this.state.documentDetailsList.length == 0 ? "" : "Documentation"} disabled={this.state.documentDetailsList.length == 0} >
                                                    {
                                                        this.state.documentDetailsList.length > 0 ?
                                                            <div className="card usercard shadow">
                                                                <div className="card-body ">
                                                                    <div className='PricePadding'>
                                                                        <ServiceDocumentation serviceId={this.state.serviceId}></ServiceDocumentation>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }

                                                </TabPanel>
                                                :
                                                null
                                            }
                                            <TabPanel header={this.state.service.type == "Single" ? "" : "Building Blocks"} disabled={this.state.service.type == "Single"} >
                                                {
                                                    this.state.buildingBlockLoading ?
                                                        <IgigLoadingIcon></IgigLoadingIcon>
                                                        :
                                                        <div className='PricePadding'>
                                                            <div className="row pt-3">
                                                                {
                                                                    this.state.BuildingBlockServices.map((model, index) => {
                                                                        return (
                                                                            <ListingCard
                                                                                key={index}
                                                                                model={model}
                                                                                routeDemo={this.state.routeDemo}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                }

                                            </TabPanel>

                                        </TabView>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !this.state.loading ?
                        <ListingFooter ></ListingFooter>
                        : null
                }
            </>
        )
    }
}
export default ServicePreview;