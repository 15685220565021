import React, { Component } from 'react';
import moment from 'moment'
import poweredBy from '../assets/images/powered-by1.png'
import CommonValues from '../Common/util';


class ListingFooter extends Component {

    onPrivacyPolicyClick = () => {
        let path = window.location.href.split('agencyonboarding/')[1]
        let forgotpasswordpath = window.location.href.split('forgotpassword/')[1]
        let setpasswordpathwithtoken = window.location.href.split('setpassword/')[1]
        let setpasswordpath = '';
        if (setpasswordpathwithtoken != '' && setpasswordpathwithtoken != null && setpasswordpathwithtoken != undefined) {
            setpasswordpath = setpasswordpathwithtoken.split('/')[1]
        }
        if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCert_Tenant_Id || 
        path == 'nowcerts' || 
        forgotpasswordpath=='nowcerts' ||
        setpasswordpath=='nowcerts') {
            window.open("/privacypolicy/nowcerts", "_blank");
        }
        else {
            window.open("/privacypolicy", "_blank");
        }
    }  


    render() {
        let year = moment().year();
        return (
            <>
                 <div className="container-fluid  poweredby-mb-div ">
                <div className="row mt-5 poweredby-logo">
                    <div className="col-md-12 text-right mt-3">
                        <img src={poweredBy} className="img-fluid" />
                    </div>
                </div>
                </div>
                <div className="container-fluid footer">
                    <div className="row p-2">
                        <div className="col-md-6 col-12  footer-content1">
                            <a  target="_blank" className="privacypolicylink" onClick={this.onPrivacyPolicyClick}>Privacy Policy</a>
                        </div>
                        <div className="col-md-6 col-12  footer-content2">
                            InsuranceGIG {year} © All Rights Reserved
                        </div>
                    </div>
                </div>


            </>
        )
    }
}
export default ListingFooter;
