import React, { Component } from 'react'

class IgigPrimaryBtn extends Component {
    render() {
        var val = '';
        return (

            <>

                <button type="button"
                    className="btn btn-warning btn-cmn"
                    style={{ width: this.props.width }}
                    onClick={this.props.onClick}
                    iconname={this.props.iconname}
                >{this.props.iconShow==1? <>
                    <i className={this.props.iconname} aria-hidden="true" /> &nbsp;&nbsp;&nbsp;   </>
                    :
                    null
                    }

                    {this.props.text}</button>
            </>
        )
    }
}
export default IgigPrimaryBtn;
