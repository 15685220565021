import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ServiceDetailDescription from './ServiceDetailDescription';
import ServiceDetailScreenshots from './ServiceDetailScreenshots';


class ServiceOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flowName: "",
            flowDescription: "",
            expectedVolume: "",
            loading: false,
            addIntegrationFlow: false
        }
    }

    componentDidMount = () => {
    }
    onWebsiteClick = (e, url) => {
        window.open(url, "_blank");
        e.preventDefault();
    }


    render() {



        return (
            <>
                <div className="row">
                    <div className="col-md-8">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"></div>

                            {this.props.mediaList.length > 0 ?
                                <div className="card mediacard" >
                                    <div className="card-body gig-services ss " >
                                        <h4 className="font-weight-600">Media</h4>
                                        <ServiceDetailScreenshots
                                            mediaList={this.props.mediaList}
                                            width={this.props.innerWidth} />
                                    </div>
                                </div>
                                :
                                null
                            }
                            <ServiceDetailDescription service={this.props.service} />
                        </div>
                    </div>

                    <div className="col-md-4">
                        {this.props.service.lineOfBusinessNames != "" && this.props.service.lineOfBusinessNames != null &&
                            this.props.service.valueChainNames != "" && this.props.service.valueChainNames != null &&
                            this.props.service.companyTypeNames != "" && this.props.service.companyTypeNames != null &&
                            this.props.service.companySubTypeNames != "" && this.props.service.companySubTypeNames != null ?

                            <div className="card">
                                <div className="card-body gig-services">
                                    <h4 className="font-weight-600">Tags</h4>

                                    {
                                        this.props.service.lineOfBusinessNames != null && this.props.service.lineOfBusinessNames != "" ?
                                            <>
                                                <div className="pt-3 pb-3">
                                                    <p className="text-12 font-weight-600 mb-2">Line of Business:</p>
                                                    {
                                                        this.props.service.lineOfBusinessNames != null ?
                                                            <>
                                                                {
                                                                    this.props.service.lineOfBusinessNames.map((item, index) => (
                                                                        <>
                                                                            <span className="badge badge-pill badge-transperent-primary m-1" key={index} style={{ cursor: "default" }}>{item}</span>

                                                                        </>
                                                                    ))}
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>

                                                <hr />
                                            </>
                                            :
                                            null}
                                    {
                                        this.props.service.valueChainNames != null && this.props.service.valueChainNames != "" ?
                                            <>
                                                <div className="pt-3 pb-3">
                                                    <p className="text-12 font-weight-600 mb-2">Insurance Value Chain:</p>

                                                    {
                                                        this.props.service.valueChainNames != null ?
                                                            <>
                                                                {
                                                                    this.props.service.valueChainNames.map((item, index) => (
                                                                        <>
                                                                            <span className="badge badge-pill badge-transperent-primary m-1" key={index} >{item}</span>
                                                                        </>
                                                                    ))}
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <hr />
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        this.props.service.companyTypeNames != null && this.props.service.companyTypeNames != "" ?
                                            <>
                                                <div className="pt-3 pb-3">
                                                    <p className="text-12 font-weight-600 mb-2">Company Type:</p>
                                                    {
                                                        this.props.service.companyTypeNames != null ?
                                                            <>
                                                                {
                                                                    this.props.service.companyTypeNames.map((item, index) => (
                                                                        <>
                                                                            <span className="badge badge-pill badge-transperent-primary m-1" key={index} >{item}</span>

                                                                        </>
                                                                    ))}
                                                            </>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <hr />
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        this.props.service.companySubTypeNames != null && this.props.service.companySubTypeNames != "" ?
                                            <div className="pt-3 pb-3">
                                                <p className="text-12 font-weight-600 mb-2">Category:</p>
                                                {
                                                    this.props.service.companySubTypeNames != null ?
                                                        <>
                                                            {
                                                                this.props.service.companySubTypeNames.map((item, index) => (
                                                                    <>
                                                                        <span className="badge badge-pill badge-transperent-primary m-1" key={index} >{item}</span>

                                                                    </>
                                                                ))}
                                                        </>
                                                        :
                                                        null
                                                }

                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            :
                            null}
                        {
                            this.props.service.website != null && this.props.service.website.length > 0 ?
                                <div className="card">
                                    <div className="card-body gig-services">
                                        <>
                                            <h4 className="font-weight-600">Website</h4>
                                            <a href={this.props.service.website} onClick={(e) => { this.onWebsiteClick(e, this.props.service.website) }}>{this.props.service.website}</a>
                                        </>
                                    </div>
                                </div>
                                : null
                        }
                    </div>

                </div>
            </>

        );
    }
}
export default (withRouter(ServiceOverview));
