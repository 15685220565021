import React, { Component } from 'react'
import LoginImg from '../../src/assets/images/login.svg';

export default class IgigDefaultBtn extends Component {
    render() {
        return (
            <button
                type="button"
                className="btn btn-outline-secondary w-auto"
                onClick={this.props.onClick}>
                <img src={LoginImg} className="mr-2 img-secondary login-icon" />{this.props.text}
            </button>

        )
    }
}
