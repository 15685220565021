import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Workflow from '../../src/assets/images/new-images/start-workflow.svg';
import Paper from '../../src/assets/images/new-images/paper.svg';
import RtgImg from '../assets/images/new-images/ready-to-go.svg';
import ReactGA from 'react-ga';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';
import Apiimg from '../assets/images/new-images/Apiimg.png'
import Bundleimg from '../assets/images/new-images/Bundleimg.png'
import Gigimg from '../assets/images/new-images/Gigimg.png'
import Amsimg from '../assets/images/new-images/Amsimg.png'


class ServiceCommonTopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            featureId: []
        }

    }

    componentDidMount=()=>{
        
        if(this.props.service.featureId != null && this.props.service.featureId !="" ){
            let myArray = this.props.service.featureId.split(',')
            this.setState({
                featureId: myArray
            })
        }

    }
    onStartWorkflowClicked = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Service details page',
            action: 'Subscribe to Workflow clicked for : '+this.props.service.name,
        });

        this.props.history.push(`/startworkflow/${this.props.service.name}/${this.props.serviceId}`)
        
    }

    onGoToApiDetailsClick = () => {

        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Service details page',
            action: 'API details clicked for : '+this.props.service.name,
        });

        if (this.props.service.openAPISpecificationURL != null && this.props.service.openAPISpecificationURL != "") {

            window.open(`/apidetails/${this.props.serviceId}`, "_blank")

        }

    }
    onGoToApiDocumentClick = () => {
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Service details page',
            action: 'API doc clicked for : '+this.props.service.name,
        });

        if (this.props.service.openApiDocuments != null && this.props.service.openApiDocuments != null) {
            window.open(this.props.service.openApiDocuments, "_blank");
        }

    }
    onLogoClicked = (e, url) => {
        window.open(url, "_blank")
        e.preventDefault();
    }
    render() {


        return (
           
            <div className="card detailtopbar">
                <div className="card-body p-3 details-bg gig-services">
                    <div className="service-title d-flex align-items-center pb-2">
                        <div className=" d-flex align-items-center">
                            <div className="icon ">  <a href={this.props.service.website} onClick={(e) => this.onLogoClicked(e, this.props.service.website)}><img src={this.props.service.logoUrl} alt={this.props.service.name} className="icon-img" /></a></div>
                            <span className="text-12 ml-2 mr-2">{this.props.service.ownerCompany}</span>
                            {this.props.service.liveStatus == "Live" ?
                                    <span className="status-tag-live status-tag" >Live</span>
                                    :
                                    null
                                }
                                {this.props.service.liveStatus == "Coming Soon" ?
                                    <span className="status-tag-pending status-tag" >Pending</span>
                                    :
                                    null
                                }
                                
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-6">
                            <h4 className="font-weight-600 service-detailes-title">{this.props.service.name}</h4>
                            <p className="tag-para-height">{this.props.service.tagline}  </p>
                        </div>
                        <div className="col-md-6" />
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-4 service-type-mb">
                        {
                            this.state.featureId!=undefined && this.state.featureId.length>0?
                            <span className="detail-service-type font-weight-600 ">Service Type : </span> 
                            :
                            null
                          }
                            {
                                this.state.featureId!=undefined? this.state.featureId.map((featureid) => {
                                    return (
                                            <img src={featureid == 1 ? Gigimg : featureid == 2 ? Apiimg : featureid == 3 ? Bundleimg :  featureid == 4 ? Amsimg  :null}
                                             alt="" className="mr-2"
                                              title={featureid == 1 ? "Available on GigMarket" : featureid == 2 ? "APIs Available for Integrations" : featureid == 3 ? "Bundled Service" :  featureid == 4 ? "Integrated with AMS": null }/>
                                    )
                                })
                                :
                                null
                            }

                           
                        </div>
                        <div className="col-md-8 service-detail-btns">
                            <button className="btn btn-primary workflow-btn mb-1" onClick={this.onStartWorkflowClicked} service={this.props.service} >
                                <img src={Workflow} className="mr-1 img-white" />Request Workflow</button>
                            {
                                this.props.service.openAPISpecificationURL != null && this.props.service.openAPISpecificationURL != "" ?
                                    <button className="btn btn-outline-primary supp-outline-primary-btn api-details-btn  mb-1" onClick={this.onGoToApiDetailsClick}><img src={Paper} class="mr-1 img-primary" />API details</button>
                                    : null
                            }
                            {
                                this.props.service.openApiDocuments != null && this.props.service.openApiDocuments != "" ?
                                    <>
                                        <button className="btn btn-primary workflow-btn  mb-1" onClick={this.onGoToApiDocumentClick}>API Docs</button>
                                    </>
                                    : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ServiceCommonTopBar);
