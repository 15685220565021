import React, { Component } from 'react';


export default class HeaderBackground extends Component {
    render() {
        return (
            <>
                <div className="img-2">
                </div>
                <div className="img-1">
                </div>
            </>


        )
    }
}
