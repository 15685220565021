import React, { Component } from 'react'

export default class IgigBlueSecondaryBtn extends Component {
    render() {
        
        return (


            <button
                type="button"
                className="btn btn-cmn3 text-12 p-1"
                onClick={this.props.onClick}
                style={{ width: this.props.width, float:this.props.float }}
                disabled={this.props.disabled}
            >
                {this.props.iconShow == 1 ? <>
                    <i className={this.props.iconname} aria-hidden="true" /> &nbsp;&nbsp;&nbsp;
                </>
                    :
                    null
                }
                {this.props.text}
            </button>


        )
    }
}
