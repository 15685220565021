import React, { Component } from 'react';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import filterImg from '../assets/images/filter6.svg';
import { withRouter } from 'react-router-dom';
import { Carousel } from 'primereact/carousel';
var axios = require('axios');

class BlogHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      blogsLoading: false
    };
     this.responsiveOptions = [

      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '991px',
        numVisible: 1,
        numScroll: 1
      }
      
    ];
    this.productTemplate = this.productTemplate.bind(this);
  }
  componentDidMount = () => {
    this.getBlogs();
  }
  getBlogs = () => {
    this.setState({
      blogsLoading: true
    })
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_Community_Url}/api/blogs/blogs?count=-1&search=`,

    };
    axios(config)
      .then((response) => {
       
        this.setState({
          blogs: response.data,
          blogsLoading: false
        });

      })
      .catch((error) => {
        console.log(error);
        this.setState({
          blogsLoading: false
        });
      });
  }
  onBlogClick = (id) => {
    this.props.history.push(`/blog/${id}`)
  }
  productTemplate(blogs) {
    return (
      <div className="product-item ">
        <div className="product-item-content">
          <div className="mb-3">
            <div className="z-index-1" >
              <div className="index-blog">
                <span className="blog-date d-none">{blogs.publishDate}</span>
                <div className="card mt-1">
                  <div className="card-body p-2 blog-card-height">
                    <div className=" blog-description pt-0">
                      <img className="blog-bgimg" src={blogs.thumbnailImage} />
                      <div className="pl-2">
                        <span className="text-14 font-weight-bold">{blogs.title !=null && blogs.title != undefined && blogs.title.length>50? blogs.title.substring(0,50) + '...' :blogs.title}</span>
                        <span className="d-block text-12" dangerouslySetInnerHTML={{ __html: (blogs.description != null && blogs.description != undefined && blogs.description.length > 70) ? blogs.description.substring(0, 70) : blogs.description }}></span>
                      </div>
                    </div>
                    <div className="com-blog-more blog-index-btn">
                      <button className="btn btn-primary-transperent" onClick={() => this.onBlogClick(blogs.id)}><img src={filterImg} /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {


    return (
      <div className="mid-container feature-blog-header">
        <div className=" mt-3" >
          {
            this.state.blogsLoading ?
              <IgigLoadingIcon ></IgigLoadingIcon>
              :
              <>
                <div className="carousel-demo">
                  <div className="">
                    <Carousel value={this.state.blogs} numVisible={3} numScroll={1} responsiveOptions={this.responsiveOptions} className="custom-carousel" circular
                      // autoplayInterval={3000} 
                      itemTemplate={this.productTemplate} />
                  </div>
                </div>
              </>
          }
        </div>
      </div>
    )
  }
}
export default withRouter(BlogHeader);
