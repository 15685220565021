import React, { Component } from 'react';
import axios from 'axios';
import { TabView, TabPanel } from 'primereact/tabview';
import ServiceOverview from './ServiceOverview';
import ListingCard from './ListingCard';
import { animateScroll as scroll, scroller } from 'react-scroll'
import { Redirect, withRouter } from 'react-router-dom';
import MainHeader from '../OtherCommonPages/MainHeader';
import ListingFooter from '../Common/ListingFooter';
import ServiceCommonTopBar from './ServiceCommonTopBar';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import ServiceDocumentation from './ServiceDocumentation';
import TryIt from '../TryIt/TryIt';
import Sidebar from '../OtherCommonPages/Sidebar';


class ListingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            service: {},
            serviceId: null,
            loading: false,
            mediaList: [],
            videosUrl: "",
            BuildingBlockServices: [],
            documentDetailsList: [],
            buildingBlockLoading: false,
            showPriceDetails: false,
            serviceName: "",
            disabled: false,
            innerWidth: 1024,
            notFound: false,
            routeDemo: false,
            showSidebar: false,
        }
    }
    componentDidMount = () => {

        this.updateWindowSize();
        window.addEventListener("resize", this.updateWindowSize);
        scroll.scrollToTop();
        const {
            match: { params },
        } = this.props;

        this.setState({
            serviceId: params.index
        });

        this.getServiceDataById(params.index);
        this.getMediaById(params.index);
        this.getServiceDocumentByIdAndType(params.index);
    }
    updateWindowSize = () => {
        this.setState({ innerWidth: window.innerWidth });
    }
    getBuildingBlock = (Id) => {

        this.setState({
            buildingBlockLoading: true
        });

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/Marketplace/buildingblocks?id=${Id}`,

        };

        axios(config)
            .then((response) => {

                this.setState({
                    BuildingBlockServices: response.data,
                    buildingBlockLoading: false,
                    routeDemo: true
                })
            })
            .catch((error) => {
                this.setState({
                    buildingBlockLoading: false
                });
            });


    };
    getServiceDataById = (Id) => {
        this.setState({
            loading: true
        });

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/Marketplace/id?id=${Id}`,

        };

        axios(config)
            .then((response) => {
               
                this.setState({
                    service: response.data,
                    serviceName: response.data.name,
                    showPriceDetails: response.data.showPriceDetails,
                    loading: false
                });

                if (response.data == "") {
                    this.setState({ notFound: true });
                } else {
                    this.setState({ notFound: false });
                }

                if (response.data.type == "Bundled") {
                    this.getBuildingBlock(Id);
                }


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
                if (error.response != null) {
                    if (error.response.status == 400) {
                        this.setState({ notFound: true });
                    }
                }
            });
    }
    getServiceDocumentByIdAndType = (id) => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/marketplace/servicedocuments?id=${id}&name=${""}`,

        };

        axios(config)
            .then((response) => {

                this.setState({
                    documentDetailsList: response.data,

                })
            })
            .catch((error) => {
                console.log(error);
            });

    }
    getMediaById = (Id) => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_MarketplaceService_Url}/api/Marketplace/mediaid?id=${Id}`,

        };
        axios(config)
            .then((response) => {

                this.setState({
                    mediaList: response.data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    ChangeShowSidebar = (val) => {
        this.setState({
            showSidebar: val
        });
    }

    render() {

        if (this.state.notFound == true) {
            return <Redirect to="/notfound" />
        }
        return (

            <div className="listing-details-main">
                <MainHeader showSidebar={this.state.showSidebar}
                pageName="servicedetails"
                ></MainHeader>
                <div className="wrapper d-flex align-items-stretch">

                    <Sidebar
                        showSidebar={this.ChangeShowSidebar}
                    ></Sidebar>

                    <div id="content main " className={this.state.showSidebar ? " details-content-width content mid-container detail-content" : "content mid-container detail-content"} >
                        <div className=" container-fluid service-details mobile-service-bg">
                            {
                                this.state.loading ?
                                    <IgigLoadingIcon margin="100px"></IgigLoadingIcon>
                                    :
                                    <>
                                        <ServiceCommonTopBar service={this.state.service} serviceId={this.state.serviceId} ></ServiceCommonTopBar>

                                    </>
                            }
                        </div>
                        <div className='container-fluid service-details'>
                            <div className="card">
                                <div className="card-body p-3 gig-services details-tabss">
                                    {this.state.loading ?
                                        <IgigLoadingIcon></IgigLoadingIcon>
                                        :
                                        <TabView className='listing-service'>
                                            <TabPanel header="Overview" >

                                                <ServiceOverview
                                                    service={this.state.service}
                                                    apiId={this.state.serviceId}
                                                    mediaList={this.state.mediaList}
                                                    videosUrl={this.state.videosUrl}
                                                    showPriceDetails={this.state.showPriceDetails}
                                                    innerWidth={this.state.innerWidth}
                                                />
                                            </TabPanel>
                                            <TabPanel header="Pricing" >
                                                <div className="card usercard ">

                                                    <div className="card-body ">
                                                        <div className="row">
                                                            <div className='col-12 PricePadding pricingheight'>
                                                                <p className='text-center prcing-link'>For pricing details, please contact us at <a href="mailto:support@insurancegig.com">support@insurancegig.com</a></p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </TabPanel>
                                            {this.state.service.showTryOut == false ?
                                                null
                                                :
                                                <TabPanel header={this.state.service.showTryOut == false ? "" : "Try it"} disabled={this.state.service.showTryOut == false} >
                                                    {
                                                        <TryIt service={this.state.service} serviceId={this.state.serviceId}>
                                                        </TryIt>
                                                    }
                                                </TabPanel>
                                            }
                                            {this.state.documentDetailsList.length != 0 ?
                                                <TabPanel header={this.state.documentDetailsList.length == 0 ? "" : "Documentation"} disabled={this.state.documentDetailsList.length == 0} >
                                                    {
                                                        this.state.documentDetailsList.length > 0 ?
                                                            <div className="card usercard shadow">
                                                                <div className="card-body ">
                                                                    <div className='PricePadding'>
                                                                        <ServiceDocumentation serviceId={this.state.serviceId}></ServiceDocumentation>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                    }

                                                </TabPanel>
                                                :
                                                null
                                            }
                                            <TabPanel header={this.state.service.type == "Bundled" ? "Building Blocks": ""} disabled={this.state.service.type != "Bundled"} >
                                                {
                                                    this.state.buildingBlockLoading ?
                                                        <IgigLoadingIcon></IgigLoadingIcon>
                                                        :
                                                        <div className='PricePadding'>
                                                            <div className="row pt-3">
                                                                {
                                                                    this.state.BuildingBlockServices.map((model, index) => {
                                                                        return (
                                                                            <ListingCard
                                                                                key={index}
                                                                                model={model}
                                                                                routeDemo={this.state.routeDemo}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                }

                                            </TabPanel>

                                        </TabView>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !this.state.loading ?
                        <ListingFooter ></ListingFooter>
                        : null
                }
            </div>
        );
    }
}

export default withRouter(ListingDetails);
