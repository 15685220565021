import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import CommonValues from '../Common/util';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
class LinkAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            registerURL: "",
            identityProviderError: "",
            linkprocess: false,
            linksuccess: false
        }



    }
    componentDidMount = () => {

    }

    //   onLinkClick = () => {
    //     //window.open(this.state.registerURL, "_self");
    //   }
    onLinkClick = () => {
        this.setState({ linkprocess: true });
        this.setState({ linksuccess: true });
        var axios = require('axios');
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_Local_Url}/linkuser?`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.access_token}`
            }
        };
        axios(config)
            .then((response) => {
                this.setState({ linkprocess: false });
                
                CommonValues.SetValues(
                    response.data.token,
                    response.data.userId,
                    response.data.orgName,
                    response.data.userName,
                    response.data.roleId,
                    response.data.avatarName,
                    response.data.firstName,
                    response.data.lastName,
                    response.data.iconURL,
                    response.data.hasGIGMarket,
                    response.data.slugURL,
                    response.data.headerText,
                    response.data.tenantId
                );

                if (response.data.orgName === null || response.data.orgName === "") {
                    window.location.href = "/company";
                }
                else if ((response.data.roleId == 3 || response.data.roleId == 2) && response.data.hasGIGMarket == true) {
                    window.location.href = "/" + response.data.slugURL + "/myservices";
                }
                else {
                    window.location.href = "/dashboard";
                }
            })
            .catch((error) => {
                this.setState({ linkprocess: false });
                let errorMessage;
                if (error.response != null) {
                    errorMessage = "Unknown error while link process.";
                }
                else {
                    errorMessage = "Unknown error while link process.";
                }
                this.setState({
                    errorMessage: errorMessage
                });
            });
    }

    render() {
        return (
            <>

                <div className="register-msg-font successmsg-a-font text-center" style={{
                    borderWidth: "0px", padding: "0.5rem 0.5rem", fontSize: "20px"
                }} >
                    <h5>Account Linking</h5>
                    {this.state.linksuccess != true && this.state.errorMessage == ''? 
                        <>
                            <div>It looks like you have another account with the same email address. We recommend you link these accounts.</div><br /><br />
                            <div>
                                <button type="button" class="btn btn-secondary" onClick={this.onLinkClick} >Continue To Link Account</button>
                            </div>
                        </> : null
                    }
                    {this.state.linkprocess != false ?
                        <><IgigLoadingIcon  ></IgigLoadingIcon><p>Processing Linking....</p><br /></> : null
                    }

                    {this.state.errorMessage != '' ?
                        <><p style={{ color: 'red' }}>{this.state.errorMessage}</p></> : null
                    }

                    {/* {this.state.linksuccess ?
                        <><p style={{ color: 'green' }}>Your account linked successfully,Please <a href="/login" className="registration-b" style={{ display: "inline-block" }}>login</a> to continue</p></> : null
                    } */}
                </div>
            </>


        );
    }
}

export default withRouter(LinkAccount)