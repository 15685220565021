import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Relativity6NAICS from './Relativity6NAICS';
import PDFOcr from './PDFOcr';
import LOB from './LOB';
import ReactGA from 'react-ga';
import { PageView, initGA } from '../OtherCommonPages/TrackingFile.js';

class TryIt extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount=()=>{
        initGA(process.env.REACT_APP_Tracking_Id);
        PageView();
        ReactGA.event({
            category: 'Service details page',
            action: 'Try clicked for service id: '+ this.props.serviceId,
        });
    }
    render() {
    
        return (
            <div className=' '>

                {
                    this.props.serviceId == process.env.REACT_APP_TRY_OUT_R6 ?
                        <Relativity6NAICS service={this.props.service} serviceId={this.props.serviceId}></Relativity6NAICS>
                        : null
                }

                {
                    this.props.serviceId == process.env.REACT_APP_TRY_OUT_Sensible_OCR ?
                        <PDFOcr service={this.props.service} serviceId={this.props.serviceId}></PDFOcr>
                        : null
                }
                
                {
                    this.props.serviceId == process.env.REACT_APP_TRY_OUT_LOB ?
                        <LOB service={this.props.service} serviceId={this.props.serviceId}></LOB>
                        : null
                }

            </div>
        );
    }

}


export default TryIt;

