import React, { Component } from 'react'
import ListingFooter from '../Common/ListingFooter';
import MiddleHeader from '../Common/MiddleHeader';
import IgigLoadingIcon from '../Components/IgigLoadingIcon';
import CommonValues from '../Common/util';
import AgentHeader from '../OtherCommonPages/AgentHeader';


export default class Partner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tenantname:"",
            agencyname: "",
            firstname: "",
            lastname: "",
            email: "",
            checkonboardingLoading: false,
            isAgencyExist: false,
            isUserExist: false,
            agencySlugh: ""
        }
    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;

        debugger;
        const queryString = require('query-string');
        var axios = require('axios');
        let queries = queryString.parse(this.props.location.search);        
        this.setState(queries);
       // sourceString.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        var email = queries.email;
        var firName = queries.firstname;
        var lstName = queries.lastname;
        var contctNumber = queries.contactnumber.replaceAll(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');//.replaceAll(/[^0-9]/g, '');
        contctNumber = queries.contactnumber.replaceAll(/[^0-9]/g, '');
        var agncyName = queries.agencyname;
        var agency_code = queries.agencycode;
        var agent_email  = queries.email;
        var utmSource= queries.utm_source;

        //return;
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_Local_Url}/api/account/partner?tenantName=${queries.tenantname}&agencyName=${queries.agencyname}&firstName=${queries.firstname}&lastName=${queries.lastname}&email=${queries.email}&contactNumber=${queries.contactnumber}`,
            headers: {}
        };
        this.setState({
            checkonboardingLoading: true,

        })
        axios(config)
            .then((response) => {

                this.setState({
                    checkonboardingLoading: false,
                    isAgencyExist: response.data.isAgencyExist,
                    isUserExist: response.data.isUserExist,
                    agencySlugh: response.data.slughUrl

                })

                localStorage.setItem("IsPartnerWorking",true);
                if(email != null)
                {
                    localStorage.setItem("Partner_Email",email);
                }
                if(firName != null)
                {
                    localStorage.setItem("Partner_FirstName",firName);
                }
                if(lstName != null)
                {
                    localStorage.setItem("Partner_LastName",lstName);
                }
                if(contctNumber != null)
                {
                    localStorage.setItem("Partner_ContactNumber",contctNumber);
                }
                if(agncyName != null)
                {
                    localStorage.setItem("Partner_AgencyName",agncyName);
                }
                if(agency_code != null)
                {
                    localStorage.setItem("Partner_AgencyCode",agency_code);
                }
                if(agent_email != null)
                {
                    localStorage.setItem("Partner_AgentEmail",agent_email);
                }

                if(response.data.isUserExist)
                {
                    this.props.history.push("/login")
                }
                else if(response.data.isAgencyExist && response.data.isUserExist == false)
                {
                    this.props.history.push("/agentonboarding/"+response.data.slugURL)
                }
                else if(response.data.isAgencyExist== false && response.data.isUserExist == false && response.data.isTenantExist == false)
                {                 
                    if(utmSource != null)   
                    {
                        this.props.history.push("/agencyonboarding?utm_source="+utmSource)
                    }
                    else
                    {
                        this.props.history.push("/agencyonboarding")
                    }
                }
                else if(response.data.isAgencyExist== false && response.data.isUserExist == false && response.data.isTenantExist)
                {  

                    if(utmSource != null)   
                    {
                        this.props.history.push("/agencyonboarding/"+ response.data.tenantName.toLowerCase()+"?utm_source="+utmSource)
                    }
                    else
                    {
                        this.props.history.push("/agencyonboarding/"+ response.data.tenantName.toLowerCase())
                    }
                }  
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    checkonboardingLoading: false,
                })
                this.props.history.push("/login")
            });
            


    }

    render() {
        return (
            <div className='agency-maindiv'>
                <AgentHeader pageName="agencyonboarding"></AgentHeader>
                <div className="row bg-community-banner mr-0 ml-0 row-position">
                    <div className="leftbg app-bg1  col-lg-3 col-md-2 col-1 pl-0 pr-0">
                    </div>
                    <div className="midbg  col-lg-6 col-md-8 col-10 pl-0 pr-0 ">
                        <div className="continer2 text-left pt-4 ">
                            <MiddleHeader heading1="Welcome to InsuranceGIG" heading2="Connecting Buyers and Sellers through Insurance Microservices"></MiddleHeader>
                            <div className=" mid-sec float-left account-content">

                                {
                                    this.state.loading ?
                                        <IgigLoadingIcon></IgigLoadingIcon>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className="rightbg app-bg2 col-md-2 col-lg-3 col-1 pl-0 pr-0">
                    </div>
                </div>
                <div className="container-fluid mid2 mt-2">
                    <div className="row pb-2 pt-2">
                        <div className="col-md-12 col-12 text-left">

                        </div>
                    </div>
                </div>
                <ListingFooter></ListingFooter>
            </div>
        )
    }
}
// export default withRouter(Partner)