import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';

export default class IgigDropdown extends Component {
  render() {
    return (
        <Dropdown
        value={this.props.value}
        className="form-control"
        options={this.props.options}
        onChange={this.props.onChange}
        optionLabel={this.props.optionLabel}
        placeholder={this.props.placeholder}
        disabled={this.props.isDisableEmployeeRange? true : false}
    />

    );
  }
}
